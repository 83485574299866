import happyrainConfig from "./pages/happyrain/happyrain.config";
import homeConfig from "./pages/home/home.config";
import mapConfig from "./pages/map/map.config";
import newsConfig from "./pages/news/news.config";
import productConfig from "./pages/product/product.config";
import solutionConfig from "./pages/solution/solution.config";
import weatherConfig from "./pages/weather/weather.config";
import newHomeConfig from "./pages/newhome/newHomeConfig";
import aboutConfig from "./pages/abouts/abouts.config";
import reportConfig from "./pages/report/report.config"
import policyConfig from "./pages/policy/policyConfig";

const appConfigs = [
  policyConfig,
  happyrainConfig,
  homeConfig,
  mapConfig,
  reportConfig,
  productConfig,
  solutionConfig,
  weatherConfig,
  newsConfig,
  newHomeConfig,
    aboutConfig,
];

export default appConfigs;
