import React from "react";
// import i18next from "i18next";
//
// import vi from "./i18n/vi";
// import en from "./i18n/en";
import authRoles from "app/auth/auth_roles";

// i18next.addResourceBundle("vi", "home", vi);
// i18next.addResourceBundle("en", "home", en);

const aboutConfig = {
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: "/ve-chung-toi/khach-hang",
            exact: true,
            component: React.lazy(() => import("./Customer")),
        },
        {
            path: "/ve-chung-toi/khach-hang/:url",
            exact: true,
            component: React.lazy(() => import("./DetailCustomer")),
        },
        {
            path: "/ve-chung-toi/lien-he",
            exact: true,
            component: React.lazy(() => import("./Contact")),
        },
        {
            path: "/ve-chung-toi/gioi-thieu-cong-ty",
            exact: true,
            component: React.lazy(() => import("./AboutUsmore")),
        },
        {
            path: "/ve-chung-toi/doi-ngu-chuyen-gia",
            exact: true,
            component: React.lazy(() => import("./TeamOfExperts")),
        },
        {
            path: "/ve-chung-toi/bao-chi-viet-ve-chung-toi",
            exact: true,
            component: React.lazy(() => import("./Press")),
        },
        // {
        //     path: "/ve-chung-toi/doi-ngu-lanh-dao",
        //     exact: true,
        //     component: React.lazy(() => import("./TeamOfLeaders")),
        // },
        {
            path: "/ve-chung-toi/tuyen-dung",
            exact: true,
            component: React.lazy(() => import("./Recruitment")),
        },
        {
            path: "/ve-chung-toi/cuoc-song-tai-weatherplus",
            exact: true,
            component: React.lazy(() => import("./LifeAtWeatherplus")),
        },
        {
            path: "/ve-chung-toi/doi-tac",
            exact: true,
            component: React.lazy(() => import("./Partner")),
        },

    ],
};

export default aboutConfig;