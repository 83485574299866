import React from "react";
import i18next from "i18next";

import vi from "./i18n/vi";
import en from "./i18n/en";
import authRoles from "app/auth/auth_roles";

i18next.addResourceBundle("vi", "home", vi);
i18next.addResourceBundle("en", "home", en);

const homeConfig = {
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/du-bao-thoi-tiet",
      exact: true,
      component: React.lazy(() => import("./HomePage")),
    },
    {
      path: "/du-bao-khi-tuong-thuy-van",
      exact: true,
      component: React.lazy(() => import("./HomeClimate")),
    },
    {
      path:"/du-bao-khi-tuong-thuy-van/:url",
      exact: true,
      component: React.lazy(() => import("./components/DetailClimate"))
    }
  ],
};

export default homeConfig;
