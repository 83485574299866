import * as Actions from "../actions/recentnews.action";

const initialState = () => ({
  recentNews: [],
  stormNews:[],
  mostViewNews:[]
});

// eslint-disable-next-line default-param-last
const recentNewsReducer = (state = initialState(), action) => {
  switch (action.type) {
    case Actions.NEWS_SET_RECENT_NEWS:
      return {
        ...state,
        recentNews: action.payload,
      };
    case Actions.NEWS_SET_STORM_NEWS:
      return {
        ...state,
        stormNews: action.payload
      }
    case Actions.NEWS_SET_MOST_NEWS:
      return {
        ...state,
        mostViewNews: action.payload
      }
    default:
      return state;
  }
};

export default recentNewsReducer;
