import React from "react";
import authRoles from "app/auth/auth_roles";

const productConfig = {
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/(giai-phap|solutions)",
      exact: true,
      component: React.lazy(() => import("./SolutionsPage")),
    },
    {
      path: "/(thoi-tiet-theo-nganh|solutions)/:url",
      exact: true,
      component: React.lazy(() => import("./SolutionDetailsPage")),
    },
  ],
};

export default productConfig;
