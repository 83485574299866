const locale = {
  // Navbar
  Weather: "Weather",
  Products: "Products",
  About: "About",
  Solutions: "Solutions",
  Hiring: "Hiring",

  Contact: "Contact",
  Home: "Home",
  "Weather forecasts": "Weather forecasts",
  Weather4Business: "Business+",
  WeatherCareer: "Solution for careeer",

  "About us": "About us",
  "Vision - Mission": "Vision - Mission",
  "Core value": "Core value",
  Milestones: "Milestones",
  Awards: "Awards",

  Projects: "Projects",
  Agriculture: "Agriculture",
  Hydroelectric: "Hydroelectric",
  NewsWeather:"Weather news",
  News: "News",
  "Press releases": "Press releases",
  "Life at WeatherPlus": "Life at WeatherPlus",
  "Cooperation information": "Cooperation information",
  Technology: "Technology",

  Recruitment: "Recruitment",
  "Job opportunities": "Job opportunities",
  "Employment policies": "Employment policies",
  "Recruitment process": "Recruitment process",

  "Happy rain": "HAPPY RAIN",

  // Language
  English: "English",
  Vietnamese: "Vietnamese",

  // Location dialog
  Agree: "Agree",
  "Share your location?": "Share your location?",
  "Agree to see the weather in your location":
    "Agree to see the weather in your location",

  // Footer
  Introduction:
    "WeatherPlus is the pioneer in Vietnam weather forecasting industry. Our mission is to help businesses and individuals manage weather challenges by offering reliable weather forecast and data.",
  Hotline: "Hotline",
  "Contact Information": "Contact Information",
  "Contact us for consultations on weather solutions":
    "Contact us for consultations on weather solutions",
  Address:
    "18th floor, Tower A, Song Da Building, Pham Hung, Nam Tu Liem, Hanoi",
  "View map": "View map",
};

export default locale;
