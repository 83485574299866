import * as Actions from "../actions";

const initialState = () => ({
  idDialogOpened: false,
  searchHistory: [],
  temperatureUnit: "C",
  mapPosition: {
    zoom: 6,
    center: {
      lat: 0,
      lng: 0,
    },
  },
  stormContactForm: {
    data: null,
    statusCode: null,
    errorCode: null,
  },
  userContactForm: {
    data: null,
    statusCode: null,
    errorCode: null,
  },
  urlPdfClimate:{
    data:null,
    fetching:true
  }
});

export const migrations = {
  0: () => initialState,
};

// eslint-disable-next-line default-param-last
const searchReducer = (state = initialState(), action) => {
  switch (action.type) {
    case Actions.HOME_SET_DIALOG_STATE:
      return {
        ...state,
        idDialogOpened: action.payload,
      };
    case Actions.HOME_SET_HISTORY:
      return {
        ...state,
        searchHistory: action.payload,
      };
    case Actions.HOME_SET_MAP:
      return {
        ...state,
        mapPosition: action.payload,
      };
    case Actions.HOME_STORM_INPUT_FORM:
      return {
        ...state,
        stormContactForm: {
          data: action.payload.data,
          statusCode: action.payload.status,
          errorCode: action.payload.data.errorCode,
        },
      };
    case Actions.HOME_USER_INPUT_FORM:
      return {
        ...state,
        userContactForm: {
          data: action.payload.data,
          statusCode: action.payload.status,
          errorCode: action.payload.data.errorCode,
        },
      };
    case Actions.HOME_RESET_USER_INPUT_FORM:
      return {
        ...state,
        userContactForm: {
          data: null,
          statusCode: null,
          errorCode: null,
        },
      };
    case Actions.HOME_SET_URL_PDF:
      return {
        ...state,
        urlPdfClimate: {

          data:action.payload,
          fetching:false
        }
      }
    default:
      return state;
  }
};

export default searchReducer;
