import PropTypes from "prop-types";
import React from "react";

import Loading from "./loading";
/**
 * React Suspense defaults
 * For to Avoid Repetition
 */
const Suspense = (props) => (
  <React.Suspense fallback={<Loading {...props.loadingProps} />}>
    {props.children}
  </React.Suspense>
);

Suspense.displayName = "Suspense";

Suspense.propTypes = {
  loadingProps: PropTypes.object,
  children: PropTypes.node,
};

Suspense.defaultProps = {
  loadingProps: { delay: 0 },
  children: null,
};

export default Suspense;
