import weatherService from "app/services/weather";
import { Languages } from "app/values/settings";

export const BUSINESS_NEWS_SET_DATA = "BUSINESS_NEWS_SET_DATA";
export const BUSINESS_NEWS_SET_ERROR = "BUSINESS_NEWS_SET_ERROR";

export const getBusinessNews = (language, page) => async (dispatch) => {
  try {
    const url = language === Languages.vi ? "thoi-tiet-cho-doanh-nghiep" : "weather-for-business";
    // const url = language === Languages.vi ? "thoi-tiet-doanh-nghiep" : "weather-for-business";
    const data = await weatherService.getNews(url, language, page);
    data.data.result.posts = data.data.result.posts.slice(0, 4);

    dispatch({
      type: BUSINESS_NEWS_SET_DATA,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BUSINESS_NEWS_SET_ERROR,
      payload: error,
    });
  }

};
