const locale = {
  "Latest news": "Tin tức mới nhất",
  "Most view news":"Được xem nhiều nhất",
  "Storm news":"Tin bão",
  "See more": "Xem tất cả bài viết",
  "See more news home":"Xem thêm",
  "Post date": "Đăng ngày",
  Categories: "Chuyên mục",
  News: "Tin tức",
  Tags: "Tags",
  "Weather for businesses": "Thời tiết Doanh nghiệp",
};

export default locale;
