import { combineReducers } from "redux";
import { createMigrate, persistReducer } from "redux-persist";
import searchReducer, { migrations } from "./search.reducer";
import localForage from "localforage";

const MIGRATION_DEBUG = false;

const searchPersistConfig = {
  key: "searchStore",
  version: 0,
  storage: localForage,
  migrate: createMigrate(migrations, { debug: MIGRATION_DEBUG }),
};

const reducer = combineReducers({
  search: persistReducer(searchPersistConfig, searchReducer),
});

export default reducer;
