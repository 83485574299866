const locale = {
  "Latest news": "Latest news",
  "Most view news":"Most viewed",
  "Storm news":"Hurricane news",
  "See more": "See more",
  "See more news home":"See more",
  "Post date": "Post date",
  Categories: "Categories",
  News: "News",
  Tags: "Tags",
  "Weather for businesses": "Weather for businesses",
};

export default locale;
