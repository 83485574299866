import jwtService from "app/services/jwt";
import * as UserActions from "./user.action";

export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_SUCCESSS = "AUTH_SUCCESSS";
export const AUTH_LOADING = "AUTH_LOADING";

export const register = ({ email, password }) => (dispatch) => {
  dispatch({ type: AUTH_LOADING });

  jwtService
    .register(email, password)
    .then(() => dispatch({ type: AUTH_SUCCESSS }))
    .catch((error) => {
      dispatch({
        type: AUTH_ERROR,
        payload: error,
      });
      window.alert(error);
    });
};

export const submitLogin = (payload) => (dispatch) => {
  dispatch({ type: AUTH_LOADING });

  return jwtService
    .signInWithEmailAndPassword(payload)
    .then((user) => {
      dispatch(UserActions.setUserData(user));

      dispatch({ type: AUTH_SUCCESSS });

      return user;
    })
    .catch((error) => {
      dispatch({
        type: AUTH_ERROR,
        payload: error,
      });

      throw error;
    });
};

// export const logout = () => {
//   jwtService.logout();
//   return (dispatch) => {
//     dispatch(UserActions.removeUserData());
//   };
// };
