import React from "react"
import authRoles from "app/auth/auth_roles";

const newHomeConfig = {
    auth:authRoles.onlyGuest,
    routes: [
        {
            path: "/",
            exact:true,
            component: React.lazy(() => import("./HomeNew"))
        }
    ]
}
export default newHomeConfig;
