import * as Actions from "../actions/businessnews.action";

const initialState = () => ({
  error: null,
  businessNews: {
    data: {
      tag: null,
      result: {
        page: 0,
        limit: 10,
        total: 0,
        totalPages: 0,
        posts: [],
      },
      post: null,
    },
  },
});

const businessNewsReducer = (state = initialState(), action) => {
  switch (action.type) {
    case Actions.BUSINESS_NEWS_SET_DATA:
      return {
        ...state,
        businessNews: action.payload,
      };
    case Actions.BUSINESS_NEWS_SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default businessNewsReducer;
