const locale = {
    BenefitMetplus1:"Dữ liệu đầy đủ",
    BenefitDescription1:"Metplus cung cấp đầy đủ tất cả các thông tin và dữ liệu thời tiết bạn cần: Cảnh báo mưa dông, dự báo thời tiết chi tiết tới từng giờ, dự báo khí hậu, dữ liệu thời tiết quá khứ.",
    BenefitMetplus2:"Dự báo tin cậy",
    BenefitDescription2:"Metplus tự hào là nền tảng đầu tiên và duy nhất tại Việt Nam cung cấp dữ liệu và thông tin dự báo thời tiết tin cậy nhất giúp doanh nghiệp và người dân chủ động ứng phó với diễn biến của thời tiết",
    BenefitMetplus3:"Giao diện trực quan",
    BenefitDescription3:"Với Metplus các thông tin dự báo thời tiết không còn khô cứng nữa. Tất cả đã được tích hợp trong một nền tảng duy nhất với giao diện cực kỳ thân thiện, dễ dùng cho bất kỳ khách hàng thuộc bất kỳ lĩnh vực nào.",
    BenefitMetplus4:"Hoàn toàn tự động",
    BenefitDescription4:"Công nghệ máy học (machine learning) và trí tuệ nhân tạo (AI) được tích hợp trong Metplus bên cạnh các tính năng cực kỳ tiện lợi sẽ giúp khách hàng cảm nhận Metplus như một trợ lý thời tiết tuyệt vời và ân cần.",
    BenefitMetplus5:"Khuyến nghị tức thời",
    BenefitDescription5:"Ngay khi thời tiết có những diễn biến thuận lợi hoặc không thuận lợi Metplus đều gửi thông báo qua email và Zalo ngay lập tức giúp Khách hàng chủ động ra quyết định tốt hơn.",
    BenefitMetplus6:"Mọi lúc mọi nơi",
    BenefitDescription6:"Giờ đây khách hàng có thể chủ động quản lý được các thách thức mà thời tiết gây ra cho tổ chức/doanh nghiệp mình bằng Metplus ngay trên điện thoại di động.",
};

export default locale;
