import axios from "axios";
import { wpDomainHappyRain } from "app/configs/wp.config";
import history from "@history";
import apiConfig from "app/configs/api.config";

const wpApi = axios.create({
  baseURL: `${wpDomainHappyRain}/api`,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const callApiBackend = async (uri, payload) => {
  wpApi.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    apiConfig.accessTokenKey
  )}`;
  const res = await wpApi
    .post(uri, payload)
    .then((response) => {
      if (response.status === 401) {
        history.replace("/");
      }
      return response;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        history.replace("/");
      }
      return error.response;
    });
  return res;
};

export const callApiBackendNoAuth = async (uri, payload) => {
  const res = await wpApi
    .post(uri, payload)
    .then((response) => {
      if (response.status === 401) {
        history.replace("/");
      }
      return response;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        history.replace("/");
      }
      return error.response;
    });
  return res;
};

export const getApiBackend = async (uri) => {
  wpApi.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    apiConfig.accessTokenKey
  )}`;
  const res = await wpApi
    .get(uri)
    .then((response) => {
      if (response.status === 401) {
        history.replace("/");
      }
      return response;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        history.replace("/");
      }
      return error.response;
    });
  return res;
};

export default {
  callApiBackend,
  getApiBackend,
  callApiBackendNoAuth,
};
