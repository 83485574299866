import React from "react";
import authRoles from "app/auth/auth_roles";
import i18next from "i18next";

import vi from "./i18n/vi";
import en from "./i18n/en";

i18next.addResourceBundle("vi", "report", vi);
i18next.addResourceBundle("en", "report", en);

const reportConfig = {
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: "/(weather-report|weather-report)",
            exact: true,
            component: React.lazy(() => import("./WeatherReport")),
        },
        {
            path: "/(info_landingpage|info-landingpage)",
            exact: true,
            component: React.lazy(() => import("./InfoLandingPage")),
        }
    ],
};

export default reportConfig;
