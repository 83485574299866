import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Place, Smartphone, Email } from "@material-ui/icons";
import { ChevronRight } from "@material-ui/icons";
import {ProfileFilled, DownloadOutlined } from "@ant-design/icons"

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";

import { container } from "assets/jss/weatherplus.js";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { Languages } from "app/values/settings";
import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { ReactComponent as FacebookIcon } from "assets/img/facebook.svg";
import { ReactComponent as YoutubeIcon } from "assets/img/youtube.svg";
import { ReactComponent as LinkedinIcon } from "assets/img/linkedin.svg";
import { ReactComponent as EnvelopeIcon } from "assets/img/envelope.svg";
import { Helmet } from "react-helmet";
import ProfileVI from "assets/img/weather_update/home/WeatherPlus_Profile_VI.pdf"
import ProfileEN from "assets/img/weather_update/home/WeatherPlus_Profile_EN.pdf"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#333333",
    padding: "72px 0px",
    color: "rgba(255, 255, 255, 0.87)",
  },
  container,
  border: {
    [theme.breakpoints.up("lg")]: {
      padding: "0px 40px",
      borderLeft: "1px dashed #686868",
      borderRight: "1px dashed #686868",
    },
    [theme.breakpoints.down("md")]: {
      margin: "56px 0px",
      padding: "56px 0px",
      borderTop: "1px dashed #686868",
      borderBottom: "1px dashed #686868",
    },
  },
  logo: {
    marginBottom: 24,
    height: 60,
  },
  text: {
    color: "#c1c1c1",
    fontSize: 16,
    textAlign: "justify",
    textJustify: "inter-word",
    lineHeight: "1.6",
    letterSpacing: 0,
    marginBottom: 10,
  },
  heading: {
    fontSize: 18,
    lineHeight: "28px",
    fontWeight: 700,
    letterSpacing: "1px",
    paddingBottom: "36px",
    height: "76px",
  },
  phone: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: 0,
    color: theme.palette.primary.light,
  },
  info: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 16,
  },
  infoProfile:{
    display: "flex",
    // alignItems: "flex-start",
    marginBottom: 16,
    alignItems:"center"
  },
  icon: {
    width: 24,
    height: 24,
    color: theme.palette.primary.light,
    marginRight: 24,
    marginTop: 4,
  },
  gridContainer: {
    marginRight: "-20px",
    marginLeft: "-20px",
  },
  gridItem: {
    paddingRight: "20px",
    paddingLeft: "20px",
  },
  gap16: {
    marginBottom: 16,
  },
  gap8: {
    marginBottom: 8,
  },
  gap24: {
    float: "left",
    marginBottom: 8,
  },
  button: {
    fontSize: 16,
    textTransform: "none",
    borderRadius: 2,
    padding: "12px 24px 13px 24px !important",
    fontWeight: 600,
  },
  noneShadow: {
    boxShadow: "none !important",
  },
  socialIcon: {
    width: 36,
    height: 36,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    boxShadow: "0 0 18px 0 rgba(0,0,0,.3) !important",
    color: theme.palette.primary.contrastText,
    marginRight: 15,
    marginBottom: 4,
    transition: "all 300ms ease-out",
    "&:focus": {
      color: theme.palette.primary.contrastText,
      backgroundColor: "transparent",
    },
    "&:hover": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.light,
    },
    "& > svg": {
      width: 20,
      height: 20,
    },
  },
  downloadProfileIcon:{
    width: 52,
    height: 42,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    boxShadow: "0 0 18px 0 rgba(0,0,0,.3) !important",
    color: theme.palette.primary.contrastText,
    marginRight: 15,
    marginBottom: 4,
    transition: "all 300ms ease-out",
    "&:focus": {
      color: theme.palette.primary.contrastText,
      backgroundColor: "transparent",
    },
    "&:hover": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.light,
    },
    "& > svg": {
      width: 20,
      height: 20,
    },
  },
  downloadIcon:{
    paddingLeft:6
  },
  dmca: {
    margin: "10px 5px 0px 0px",
    // display: "flex",
  },
  clearBoth: {
    clear: "both",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const language = useSelector(({ settings }) => settings.language);
  return language === Languages.en ? (
    <footer className={classes.root}>
      <Helmet>
        <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
      </Helmet>
      <div className={classes.container}>
        <GridContainer className={classes.gridContainer}>
          <GridItem xs={12} lg={4} className={classes.gridItem}>
            <div>
              <LazyLoadImage
                width={228}
                height={60}
                className={classes.logo}
                src="/assets/logo/logo_nor.webp"
                alt="Weatherplus"
              />
            </div>

            <Typography className={classes.text}>
              {t("Introduction")}
            </Typography>
            <div id="DMCA-badge" className={classes.dmca}>
              <div className="dm-1 dm-1-b">
                <a href="https://www.dmca.com/" title="DMCA">
                  DMCA
                </a>
              </div>
              <div className="dm-2 dm-2-b">
                <a
                  href="http://www.dmca.com/Protection/Status.aspx?ID=a52b5736-192b-4e79-b5d7-2fd229593a0b"
                  title="DMCA"
                >
                  PROTECTED
                </a>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} lg={4} className={classes.gridItem}>
            <div className={classes.border}>
              <Typography className={classes.heading}>
                {t("Hotline")}
              </Typography>
              <Typography className={`${classes.phone} ${classes.gap16}`}>
                +84 24 37 955 069
              </Typography>
              <Typography className={`${classes.phone} ${classes.gap8}`}>
                +84 822 091 091
              </Typography>
              <Typography className={`${classes.text} ${classes.gap24}`}>
                {t("Contact us for consultations on weather solutions")}
              </Typography>
              {language === Languages.vi ? (
                <Button
                  rightIcon
                  href="http://about.weatherplus.vn/lien-he/"
                  className={`${classes.button} ${classes.noneShadow}`}
                >
                  {t("View map")}
                  <ChevronRight />
                </Button>
              ) : (
                <Button
                  rightIcon
                  href="http://about.weatherplus.vn/en/contact-us/"
                  className={`${classes.button} ${classes.noneShadow}`}
                >
                  {t("View map")}
                  <ChevronRight />
                </Button>
              )}
            </div>
          </GridItem>
          <GridItem xs={12} lg={4} className={classes.gridItem}>
            <Typography className={classes.heading}>
              {t("Contact Information")}
            </Typography>
            <div className={classes.info}>
              <Place className={classes.icon} />
              <Typography className={classes.text}>{t("Address")}</Typography>
            </div>
            <div className={classes.info}>
              <Smartphone className={classes.icon} />
              <Typography className={classes.text}>
                (+84) 24 37 955 069
              </Typography>
            </div>
            <div className={classes.info}>
              <Smartphone className={classes.icon} />
              <Typography className={classes.text}>
                (+84) 822 091 091
              </Typography>
            </div>
            <div className={classes.info}>
              <Email className={classes.icon} />
              <Typography className={classes.text}>
                kinhdoanh@weatherplus.vn
              </Typography>
            </div>
            <div className={classes.info}>
              <ProfileFilled className={classes.icon}/>
              <Typography className={classes.text}>
                Thông tin chi tiết công ty
              </Typography>
            </div>

            <Typography>
              <a
                href="https://www.youtube.com/channel/UC4qb3ZpsPfcnAJGZx7AjZFw"
                className={classes.socialIcon}
              >
                <YoutubeIcon />
              </a>
              <a
                href="https://www.linkedin.com/company/weatherplus"
                className={classes.socialIcon}
              >
                <LinkedinIcon />
              </a>
              <a
                href="https://www.facebook.com/weatherplus"
                className={classes.socialIcon}
              >
                <FacebookIcon />
              </a>
              <a
                href="mailto:kinhdoanh@weatherplus.vn"
                className={classes.socialIcon}
              >
                <EnvelopeIcon />
              </a>
            </Typography>
          </GridItem>
        </GridContainer>

        <div className={classes.clearFix} />
      </div>
    </footer>
  ) : (
    <footer className={classes.root}>
      <Helmet>
        <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
      </Helmet>
      <div className={classes.container}>
        <GridContainer className={classes.gridContainer}>
          <GridItem xs={12} lg={4} className={classes.gridItem}>
            <Typography className={classes.heading}>
              CÔNG TY CỔ PHẦN GIẢI PHÁP THỜI TIẾT WEATHERPLUS
            </Typography>
            <Typography className={classes.text}>
              Mã số doanh nghiệp: 0106597121
            </Typography>
            <Typography className={classes.text}>
              Cấp bởi: Phòng Đăng ký kinh doanh - Sở Kế hoạch và đầu tư Thành
              phố Hà Nội ngày 09/07/2014
            </Typography>
            <Typography className={classes.text}>
              Người đại diện theo pháp luật: Ông Tô Đức Hải
            </Typography>
            <Typography className={classes.text}>
              Giấy phép hoạt động dự báo, cảnh báo khí tượng thuỷ văn số 01 năm
              2017 do Bộ Tài nguyên và môi trường cấp ngày 03/04/2017
            </Typography>
            <Typography className={classes.text}>
              Giấy phép hoạt động dự báo, cảnh báo khí tượng thuỷ văn (sửa đổi
              lần thứ nhất) số: 14/GP-BTNMT do Bộ Tài nguyên và môi trường cấp
              ngày 21/01/2021.
            </Typography>
          </GridItem>
          <GridItem xs={12} lg={4} className={classes.gridItem}>
            <div className={classes.border}>
              <Typography className={classes.heading}>
                Các điều khoản và chính sách
              </Typography>
              <Typography
                component={"a"}
                href="https://about.weatherplus.vn/chinh-sach-quy-dinh-chung/"
                className={`${classes.text} ${classes.gap24}`}
              >
                Chính sách & quy định chung
              </Typography>
              <Typography
                component={"a"}
                href="https://about.weatherplus.vn/quy-dinh-ve-hinh-thuc-thanh-toan/"
                className={`${classes.text} ${classes.gap24}`}
              >
                Quy định về phương thức thanh toán
              </Typography>
              <Typography
                component={"a"}
                href="https://about.weatherplus.vn/chinh-sach-van-chuyen-va-giao-nhan/"
                className={`${classes.text} ${classes.gap24}`}
              >
                Chính sách vận chuyển và giao nhận
              </Typography>
              <Typography
                component={"a"}
                href="https://about.weatherplus.vn/chinh-sach-bao-mat-thong-tin/"
                className={`${classes.text} ${classes.gap24}`}
              >
                Chính sách bảo mật thông tin
              </Typography>
              <div style={{ display: "inline-flex", marginTop: 20 }}>
                <Typography
                  component={"a"}
                  href="http://online.gov.vn/Home/WebDetails/84850"
                  title="Bo Cong Thuong"
                >
                  <img
                    style={{ width: 140, marginRight: 10 }}
                    src="/assets/logo/logoSaleNoti.png"
                    alt="Bo Cong Thong"
                  />
                </Typography>
                <Typography
                  component={"a"}
                  href="https://www.dmca.com/Protection/Status.aspx?ID=a52b5736-192b-4e79-b5d7-2fd229593a0b"
                  title="Content Protection by DMCA.com"
                  className="dmca-badge"
                >
                  <img
                    src="https://images.dmca.com/Badges/dmca-badge-w150-2x1-02.png?ID=https://www.dmca.com/Protection/Status.aspx?ID=a52b5736-192b-4e79-b5d7-2fd229593a0b"
                    alt="Content Protection by DMCA.com"
                  />
                </Typography>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} lg={4} className={classes.gridItem}>
            <Typography className={classes.heading}>
              {t("Contact Information")}
            </Typography>
            <div className={classes.info}>
              <Place className={classes.icon} />
              <Typography className={classes.text}>{t("Address")}</Typography>
            </div>
            <div className={classes.info}>
              <Smartphone className={classes.icon} />
              <Typography className={classes.text}>
                (+84) 24 37 955 069
              </Typography>
            </div>
            <div className={classes.info}>
              <Smartphone className={classes.icon} />
              <Typography className={classes.text}>
                (+84) 822 091 091
              </Typography>
            </div>
            <div className={classes.info}>
              <Email className={classes.icon} />
              <Typography className={classes.text}>
                kinhdoanh@weatherplus.vn
              </Typography>
            </div>
            <div className={classes.infoProfile}>
              <ProfileFilled className={classes.icon} style={{fontSize:22}}/>
              <Typography className={classes.text} style={{margin:"0px 15px 0px 0px"}}>
                Tải Hồ sơ công ty:
              </Typography>
              <Typography>
                <a href={ProfileVI} download={"WeatherPlus_Profile_VI.pdf"} className={classes.downloadProfileIcon}>VI <DownloadOutlined className={classes.downloadIcon}/></a>
                {/*<a href={ProfileVI} download={"WeatherPlus_Profile_VI.pdf"} className={classes.iconDownload}></a>*/}
              </Typography>
              <Typography>
                <a href={ProfileEN} download={"WeatherPlus_Profile_EN.pdf"} className={classes.downloadProfileIcon}>EN <DownloadOutlined className={classes.downloadIcon}/></a>
              </Typography>
            </div>

            <Typography>
              <a
                href="https://www.youtube.com/channel/UC4qb3ZpsPfcnAJGZx7AjZFw"
                className={classes.socialIcon}
              >
                <YoutubeIcon />
              </a>
              <a
                href="https://www.linkedin.com/company/weatherplus"
                className={classes.socialIcon}
              >
                <LinkedinIcon />
              </a>
              <a
                href="https://www.facebook.com/weatherplus"
                className={classes.socialIcon}
              >
                <FacebookIcon />
              </a>
              <a
                href="mailto:kinhdoanh@weatherplus.vn"
                className={classes.socialIcon}
              >
                <EnvelopeIcon />
              </a>
            </Typography>
          </GridItem>
        </GridContainer>

        <div className={classes.clearFix} />
      </div>
    </footer>
  );
};

export default Footer;
