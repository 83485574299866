import React from "react";
import i18next from "i18next";

import vi from "./i18n/vi";
import en from "./i18n/en";
import authRoles from "app/auth/auth_roles";

i18next.addResourceBundle("vi", "news", vi);
i18next.addResourceBundle("en", "news", en);

const homeConfig = {
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/(tin-tuc|news)",
      exact: true,
      component: React.lazy(() => import("./NewsPage")),
    },
    {
      path: "/:url",
      exact: true,
      component: React.lazy(() => import("./NewsPage")),
    },
  ],
};

export default homeConfig;
