import i18n from "../../../i18n";

export const SETTINGS_SET_UNIT = "SETTINGS_SET_UNIT";
export const SETTINGS_SET_LANGUAGE = "SETTINGS_SET_LANGUAGE";

export const setTemperatureUnit = (unit) => (dispatch) => {
  dispatch({
    type: SETTINGS_SET_UNIT,
    payload: unit,
  });
};

export const setLanguage = (language) => (dispatch) => {
  dispatch({
    type: SETTINGS_SET_LANGUAGE,
    payload: language,
  });

  i18n.changeLanguage(language.toLowerCase());
};
