import React from "react";
import i18next from "i18next";

import vi from "../map/i18n/vi";
import en from "../map/i18n/en";
import authRoles from "app/auth/auth_roles";

i18next.addResourceBundle("vi", "map", vi);
i18next.addResourceBundle("en", "map", en);

const mapConfig = {
  auth: authRoles.onlyGuest,
  settings: { layout: { appBar: true, footer: false } },
  routes: [
    {
      path: "/anh-may-ve-tinh",
      exact: true,
      component: React.lazy(() => import("./MapPage")),
    },
    {
      path: "/radar-map",
      exact: true,
      component: React.lazy(() => import("./MapPage")),
    },
  ],
};

export default mapConfig;
