import headerLinksStyle from "assets/jss/weatherplus/components/headerLinksStyle.js";
import BackgroundPopupDesktop from "assets/img/weather_update/header/background-popup-desktop.png"

const pagesStyle = (theme) => ({
  ...headerLinksStyle(theme),
  wrapper: {
    height: "auto",
    minHeight: "100vh",
    backgroundColor: "#ffffff",
  },
  fullPage: {
    // padding: "55px 0 40px",
    minHeight: "100vh",
    margin: "0",
    border: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      // padding: "0px 0px 30px 0px",
      minHeight: "700px!important",
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      border: "none !important",
    },
  },
  btnMenu: {
    padding: "3px 0px 6px 0px !important",
    minWidth: "30px !important",
    "&:hover,&:focus": {
      backgroundColor: "transparent !important",
    },
    "& .MuiButton-endIcon": {
      marginLeft: "-10px !important",
      "& .MuiSvgIcon-root": {
        color: "#555555",
        "&:hover,&:focus": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  units: {
    display: "flex",
    paddingLeft: "35px",
  },
  subTitleLink:{
    display: "flex",
    justifyContent:"right"
  },
  detailPopup:{
    background:"#F4FCEA",
    border:"1px solid #D7D7D7",
    borderRadius:"25px",
    position:"fixed",
    bottom:"28px",
    left:"32px",
    zIndex: 9000,
    filter:"drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    visibility: "visible",
    opacity:1,
    transition: "visibility 0s, opacity 0.5s ease-in-out",
  },
  detailPopupDesktop:{
    backgroundImage: `url("${BackgroundPopupDesktop}")`,
    backgroundSize: "cover",
    boxShadow: "3px 3px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "30px",
    height:425,
    width: 320,
    position:"fixed",
    bottom:"40px",
    left:"10px",
    zIndex: 9000,
    padding:"6px 15px",
    visibility: "visible",
    opacity:1,
    transition: "visibility 0s, opacity 0.5s, width 0.2s, height 0.2s ease-in-out",
  },
  detailPopupDesktopHidden:{
    width:0,
    height:0,
    visibility:"hidden !important",
    opacity: 0,
    transition: "visibility 0.8s, opacity 0.2s, width 0.2s, height 0.2s ease-in-out",
  },
  detailPopupHidden:{
    visibility:"hidden !important",
    opacity: 0,
    transition: "visibility 0.5s, opacity 0.5s ease-in-out",
  },
  contentPopup:{
    padding:0,

  },
  contentDownload:{
    padding:0,
    display:"flex",
    alignItems:"center"
  },
  notePopup:{
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "12px",
    /* identical to box height */

    textAlign: "center",
    letterSpacing: "0.04em",

    /* Gray text 2 */

    color: "#727171",
  },
  imageLogoPopupApp:{
    filter:"drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    border:"1px solid #D7D7D7",
    height:65,
    borderRadius: "20px",
    [theme.breakpoints.down("sm")]:{
      height:"54px",
      borderRadius: "16px",
    }
  },
  titlePopup:{
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    /* identical to box height */

    textAlign: "center",
    letterSpacing: "0.04em",

    /* Gray text 2 */

    color: "#67A11A",
  },
  containerPopupApp:{
    position: "fixed",
    bottom:"27px",
    zIndex:100000,
    left:"7px",
    visibility:"visible",
    opacity:1,
    transition: "visibility 0.5s, opacity 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55)",
  },
  containerPopupAppHidden:{
    visibility:"hidden",
    opacity:0,
    transition: "visibility 0.5s, opacity 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55)",
  },
  buttonPopupApp:{
    border:"none",
    background: "transparent",
    display:'flex',
    alignItems: "center",
    cursor: "pointer"
  },
  coverText:{
    padding:"4px 0px"
  },
  textOfficial:{
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    /* identical to box height */

    // textAlign: "center",
    letterSpacing: "0.04em",
    textTransform:"uppercase",
    /* Gray text 2 */
    paddingBottom:"12px",
    color: "#FFFFFF",
  },
  containerButtonClosePopup:{
    textAlign:"end"
  },
  textContentPopupDesktop:{
    paddingLeft: "0px"
  },
  btnClosePopupDesktop:{
    background:"transparent",
    border:"none",
    cursor:"pointer",
    paddingRight:0
  },
  closePopupDesktop:{
    height:"15px"
  },
  titlePopupDesktop:{
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "25px",
    /* identical to box height */

    // textAlign: "center",
    letterSpacing: "0.04em",
    textTransform:"uppercase",
    /* Gray text 2 */

    color: "#FF9900",
  },
  logoAppDetail:{
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: "30px",
  },
  textUpdateWeather:{
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "16px",
    /* identical to box height */

    // textAlign: "center",
    letterSpacing: "-0.04em",
    textTransform:"uppercase",
    /* Gray text 2 */

    color: "#D7D7D7",
    padding:"8px 0px 4px 0px"
  },
  textTrialMetplus:{
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "25px",
    lineHeight: "30px",
    /* identical to box height */

    // textAlign: "center",
    letterSpacing: "-0.04em",

    /* Gray text 2 */

    color: "#FF9900",
  },
  textDetailPopupDesktop:{
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "21px",
    /* identical to box height */

    // textAlign: "center",
    letterSpacing: "-0.04em",

    /* Gray text 2 */

    color: "#FEFEFE",
    paddingTop:"8px"
  },
  tickRedPopupDesktop:{
    // paddingTop: "15px",
    width:25,
    height:38
  },
  containerTickRed:{
    display:"flex",
    justifyContent: "center",
    alignItems:"center"
  },
  textDownloadNow:{
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "20px",
    /* identical to box height */

    textAlign: "center",
    letterSpacing: "-0.04em",
    // textTransform:"uppercase",
    /* Gray text 2 */
    padding:"6px 6px 6px 0px",
    color: "#67A11A",
  },
  btnDownloadDesktop:{
    background:"#FFFFFF",
    borderRadius:"20px",
    width:"100%",
    marginBottom:"25px",
    boxShadow: "0px 2px 0px 1px #727171",
    border:"none",
    cursor:"pointer",
    "&:hover,&:focus":{
      transform:"scale(1.08)",
      boxShadow: "0px 4px 0px 1px #727171",
    }
  },
  coverIconDownload:{
    position:"relative"
  },
  containerIconDownload:{
    background:"#67A11A",
    borderRadius:"0px 2px 2px 0px",
    display:'flex',
    justifyContent:"center",
    alignItems:"center",
    position:"absolute",
    top:"-6px",
    left:"27px",
    width:36,
    height:64,
    zIndex:99999,
  },
  shadowTop:{
    position:"absolute",
    top:'-6px',
    left:"21px",
    zIndex:99998,
    width:0,
    height:0,
    borderLeft:"6px solid transparent",
    borderRight:"6px solid transparent",
    borderBottom:"5px solid #35560A"
  },
  shadowBottom:{
    position:"absolute",
    bottom:'-6px',
    left:"21px",
    zIndex:99998,
    width:0,
    height:0,
    borderLeft:"6px solid transparent",
    borderRight:"6px solid transparent",
    borderTop:"5px solid #35560A"
  },
  containerBtnDownloadDesktop:{
    textAlign:"center",
    // paddingRight:"0px",
    display:"flex",
    alignItems:"end"
  },
  containerQRApp:{
    paddingLeft:"0px"
  }
});

export default pagesStyle;
