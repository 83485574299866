import React from "react";
import i18next from "i18next";

import vi from "./i18n/vi";
import en from "./i18n/en";
import authRoles from "app/auth/auth_roles";

i18next.addResourceBundle("vi", "happyrain", vi);
i18next.addResourceBundle("en", "happyrain", en);

const homeConfig = {
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/(happy-rain|happy-rain-cho-lai-xe|happy-rain-van-phong|happy-rain-nong-nghiep)",
      exact: true,
      component: React.lazy(() => import("./HappyRainPage")),
    },
    {
      path: "/(dang-ky|register)",
      exact: true,
      component: React.lazy(() => import("./HappyRainRegisterPage")),
    },
    {
      path: "/(thanh-toan|payment)",
      exact: true,
      component: React.lazy(() => import("./HappyRainPayment")),
    },
    {
      path: "/(login|login)",
      exact: true,
      component: React.lazy(() => import("./HappyRainLoginPage")),
    },
    {
      auth: authRoles.user,
      path: "/(dashboard|dashboard)",
      exact: true,
      component: React.lazy(() => import("./HappyRainDashboardPage")),
    },
    {
      path: "/(logout|logout)",
      exact: true,
      component: React.lazy(() => import("./logout")),
    },
    {
      path: "/(error|error)",
      exact: true,
      component: React.lazy(() => import("./HappyRainError")),
    },
    {
      path: "/(map|map)",
      exact: true,
      component: React.lazy(() => import("./MapRain")),
    },
  ],
};

export default homeConfig;
