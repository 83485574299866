import React from "react";
import authRoles from "app/auth/auth_roles";
import i18next from "i18next";

import vi from "./i18n/vi";
import en from "./i18n/en";

i18next.addResourceBundle("vi", "product", vi);
i18next.addResourceBundle("en", "product", en);

i18next.addResourceBundle("vi", "news", vi);
i18next.addResourceBundle("en", "news", en);
const productConfig = {
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/(san-pham-giai-phap|products)",
      exact: true,
      component: React.lazy(() => import("./ProductsPage")),
    },
    {
      path: "/(du-bao|forecast)",
      exact: true,
      component: React.lazy(() => import("./Forecast")),
    },
    {
      path: "/(thoi-tiet-theo-nganh|weathercareer)",
      exact: true,
      component: React.lazy(() => import("./MetplusListCareer")),
    },
    {
      path: "/(san-pham-giai-phap|products)/metplus",
      exact: true,
      component: React.lazy(() => import("./Metplus")),
    },
    // {
    //   path: "/(san-pham-giai-phap|products)/metplus/careers",
    //   exact: true,
    //   components: React.lazy(() => import("./MetplusListCareer")),
    // },
    {
      path: "/(api-thoi-tiet|api-thoi-tiet)",
      exact: true,
      component: React.lazy(() => import("./ApiWeather")),
    },
    {
      path: "/(data-suites|data-suites)",
      exact: true,
      component: React.lazy(() => import("./DataSuite")),
    },
    {
      path: "/(weather-pro|weather-pro)",
      exact: true,
      component: React.lazy(() => import("./WeatherPro")),
    },
    // {
    //   path:"/weather-report",
    //   exact: true,
    //   component: React.lazy(() => import("../report/WeatherReport"))
    // }
  ],
};

export default productConfig;
