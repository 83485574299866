import * as Actions from "../actions/settings.action";

const initialState = () => ({
  temperatureUnit: "C",
  language: "VI",
});

export const migrations = {
  0: () => initialState,
};

// eslint-disable-next-line default-param-last
const settingsReducer = (state = initialState(), action) => {
  switch (action.type) {
    case Actions.SETTINGS_SET_UNIT:
      return {
        ...state,
        temperatureUnit: action.payload,
      };
    case Actions.SETTINGS_SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};

export default settingsReducer;
