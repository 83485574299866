import {List, ListItem, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppContext from "app/app_context";
//import { Languages } from "app/values/settings";
import styles from "assets/jss/weatherplus/layouts/authStyle.js";
import Button from "components/CustomButtons/Button.js";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { matchRoutes } from "react-router-config";
import { Link, useLocation } from "react-router-dom";
import i18n from "../../i18n";
// import MenuDesktop from "./components/MenuDesktop";
import MenuMaterial from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {isDesktop} from "react-device-detect";
import TemperatureUnitSwitch from "components/TabHeader/UnitSwitch";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ListMaterial from "@mui/material/List";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Fade from "@mui/material/Fade";
// import reducer from "../pages/news/store/reducers"
// import withReducer from "../store/with_reducer";
import { useHistory } from "react-router-dom";
// import { getSolutionsData } from "app/services/weather";
import LogoWeatherplus from "../../assets/img/weather_update/header/logo_weatherplus_new.jpg";
import {ReactComponent as YoutubeIcon} from "../../assets/img/youtube_black.svg";
import {ReactComponent as LinkedinIcon} from "../../assets/img/linkedin_black.svg";
import {ReactComponent as FacebookIcon} from "../../assets/img/facebook_black.svg";
import {ReactComponent as EnvelopeIcon} from "../../assets/img/envelope_black.svg";
// import logoApp2 from "assets/img/weather_update/header/logo_app_2.png"
// import ImageDownload from "assets/img/weather_update/header/download.png"
// import {getNews} from "../pages/news/store/actions";
// import ImageClose from "assets/img/weather_update/header/button-close-popup.png"
// import TickRed from "assets/img/weather_update/header/tick-red.svg"
// import IconDownload from "assets/img/weather_update/header/icon-download.svg"
// import QR_App from "assets/img/weather_update/header/QR_install_app.png"

const useStyles = makeStyles(styles);

const AuthLayout = (props) => {
  // const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const history = useHistory();
  const { routes } = appContext;
  const location = useLocation();
  const { pathname } = location;
  const matched = matchRoutes(routes, pathname)[0];

  let layout = {
    appBar: true,
    footer: true,
  };

  if (matched && matched.route.settings) {
    const routeSettings = matched.route.settings;
    if (routeSettings.layout) {
      layout = routeSettings.layout;
    }
  }

  const classes = useStyles();
  const wrapper = useRef();
  const language = useSelector(({ settings }) => settings.language);
  // const career = WeatherService.getSolutionsData();
  // const categories = carrer ? carrer.solutions : [];

  const { t } = useTranslation("common");

  // const [languageDialog, setLanguageDialog] = useState(false);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language.toLowerCase());
    }
  }, [language]);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [mobileAboutOpen,setMobileAboutOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  // const [weatherCareerAnchorEl, setWeatherCareerAnchorEl] = useState(null);
  const [forecastAnchorEl,setForecastAnchorEl] = useState(null);
  const [aboutAnchorEl, setAboutAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenuWeather,setOpenMenuWeather] = useState(false)

  const [anchorNews, setAnchorNews] = useState(null)
  const [checkMenuNews,setCheckMenuNews] = useState(false)    

  // const [checkPopupApp,setCheckPopupApp] = useState(false)

  // const handleOpenPopup = () => {
  //   setCheckPopupApp(!checkPopupApp);
  // }
  // const handleAutoOpenPopup = () => {
  //     setCheckPopupApp(true)
  // }
  // useEffect(() => {
  //        const timeoutPopup = setTimeout(handleAutoOpenPopup,10000) ;
  //        return () => {
  //            clearTimeout(timeoutPopup);
  //        }
  // },[])
    // useEffect(() => {
    //     if (checkPopupApp) {
    //        const timeoutId = setTimeout(handleClosePopup,14000);
    //         return () => {
    //            clearTimeout(timeoutId)
    //         }
    //     }
    // },[checkPopupApp])
 // const handleClosePopup = () => {
 //    setCheckPopupApp(false);
 // }
 // const openPopupApp = Boolean(checkPopupApp);
 //  const idPopupApp = openPopupApp ? 'simple-popover' : undefined;

  const handleShowMenuNewsMobile = () =>{
    setCheckMenuNews(!checkMenuNews);
  };
  const handleShowMenuNewsDesktop = (event) => {
    setAnchorNews(event.currentTarget)
  };
  const handleCloseMenuNewsDesktop = () => {
    setAnchorNews(null)
  };
  const handleClickProductMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseProductMenu = () => {
    setAnchorEl(null);
  };
  const handleClickForecastMenu = (event) => {
    setForecastAnchorEl(event.currentTarget);
  };
  const handleCloseForecastMenu = () => {
    setForecastAnchorEl(null);
  };
  const handleClickAbout = (event) => {
    setAboutAnchorEl(event.currentTarget)
  }
  const handleCloseAbout = () => {
    setAboutAnchorEl(null)
  };
  const handleClickButton = () => {
    setMobileOpen(false);
  };

  const handleClickMenuWeatherMobi = () => { setOpenMenuWeather(!openMenuWeather)}

  const handleClickMenuMobile = () => {
    setOpenMenu(!openMenu);
  };
  const handleClickMenuAboutMobile = () =>{
    setMobileAboutOpen(!mobileAboutOpen)
  }
  const HandleCloseMenuMobileAbout = () => {
    setMobileAboutOpen(false)
  }

  const handleDataPastMobile = () => {
    history.push({ state: { isCheckMobile: true } });
    handleClickMenuMobile();
  };
  const pathName = window.location.pathname;

  return (
    <div>
      {layout.appBar && (
        <Header
          fixed
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
          brand={
            <img
              className={classes.brandImage}
              src={LogoWeatherplus}
              alt="Weatherplus"
            />
          }
          links={
            <div className={classes.collapse} style={{height:"100%"}}>
              <List className={classes.list} style={{height:"100%"}}>
                <ListItem className={classes.listItem}>
                  <Button
                    disabledRipple
                    component={Link}
                    to="/"
                    className={classes.navLink + (pathName === "/" ? " " + classes.navLinkActive : "")}
                    color={"transparent"}
                    onClick={handleClickButton}
                  >
                    {t("Home")}
                  </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                  {isDesktop?
                      <>
                        <Button
                            disableRipple
                            component={Link}
                            // to="/du-bao-thoi-tiet"
                            className={classes.navLink +
                                (pathName.search("/du-bao-thoi-tiet") !== -1  ||
                                pathName.search("/du-bao-khi-tuong-thuy-van") !== -1 ? " " + classes.navLinkActive : "")}
                            color="transparent"
                            onClick={handleClickForecastMenu}
                        >
                          {t("Weather")}
                        </Button>
                        <MenuMaterial
                            id="forecast-menu"
                            keepMounted
                            open={Boolean(forecastAnchorEl)}
                            onClose={handleCloseForecastMenu}
                            className={classes.forecastMenu}
                            TransitionComponent={Fade}
                        >
                          <div className={classes.containerMenu}>
                            <GridContainer className={classes.contentCommonMenu}>
                              <GridItem xs={4} className={classes.titleMainMenu}>
                                Thời tiết & khí hậu
                              </GridItem>
                              <GridItem xs={8} className={classes.subTitleMainMenu}>
                                <MenuItem onClick={handleCloseForecastMenu}>
                                  <Button
                                      disableRipple
                                      component={Link}
                                      to="/du-bao-thoi-tiet"
                                      className={
                                          classes.navLink + " " + classes.subMegaMenu + (pathName === "/du-bao-thoi-tiet" ? " " + classes.navLinkActive : "")
                                      }
                                      color="transparent"
                                  >
                                    Dự báo thời tiết
                                  </Button>
                                </MenuItem>
                                <MenuItem onClick={handleCloseForecastMenu}>
                                  <Button
                                      disableRipple
                                      component={Link}
                                      to="/du-bao-khi-tuong-thuy-van"
                                      className={
                                          classes.navLink + " " + classes.subMegaMenu + (pathName === "/du-bao-khi-tuong-thuy-van" ? " " + classes.navLinkActive : "")
                                      }
                                      color="transparent"
                                  >
                                    Dự báo khí hậu
                                  </Button>
                                </MenuItem>
                              </GridItem>
                            </GridContainer>
                          </div>

                        </MenuMaterial>
                      </>
                      :
                      <GridContainer>
                        <GridItem xs={10}>
                          <Button
                              disableRipple
                              component={Link}
                              // to="/san-pham-giai-phap"
                              className={classes.navLink + (pathName.search("/du-bao-thoi-tiet") !== -1  || pathName.search("/du-bao-khi-tuong-thuy-van") !== -1 ? " " + classes.navLinkActive : "")}
                              color="transparent"
                              style={{ paddingRight: "0px" }}
                              onClick={handleClickMenuWeatherMobi}
                          >
                            {t("Weather")}
                          </Button>
                          <Collapse
                              in={openMenuWeather}
                              timeout={"auto"}
                              unmountOnExit
                              className={classes.detailMenuMobile}
                          >
                            <ListMaterial component={"div"} disablePadding>
                              <Link
                                  to={"/du-bao-thoi-tiet"}
                                  onClick={handleClickMenuWeatherMobi}
                              >
                                <ListItemButton sx={{ pl: 4 }}>
                                  <ListItemText
                                      primary={"Dự báo thời tiết"}
                                      onClick={handleClickButton}
                                  />
                                </ListItemButton>
                              </Link>
                              <Link
                                  to={"/du-bao-khi-tuong-thuy-van"}
                                  onClick={handleClickMenuWeatherMobi}
                              >
                                <ListItemButton sx={{ pl: 4 }}>
                                  <ListItemText
                                      primary={"Dự báo khí hậu"}
                                      onClick={handleClickButton}
                                  />
                                </ListItemButton>
                              </Link>
                            </ListMaterial>
                          </Collapse>
                        </GridItem>
                        <GridItem xs={2}>
                          <ListItemButton
                              onClick={handleClickMenuWeatherMobi}
                              className={classes.iconMenuMobile}
                          >
                            {openMenuWeather ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                        </GridItem>
                      </GridContainer>}
                </ListItem>
                {/* <ListItem className={classes.listItem}>
                  <Button
                    disableRipple
                    components={Link}
                    to=""
                    onClick={(e) => {
                      e.preventDefault();
                      language === Languages.vi
                        ? (window.location.href =
                            "https://about.weatherplus.vn/du-bao-khi-tuong-thuy-van/")
                        : (window.location.href =
                            "https://about.weatherplus.vn/en/en-report-weather/");
                    }}
                    className={classes.navLink}
                    color="transparent"
                  >
                    {t("Weather forecasts")}
                  </Button>
                </ListItem> */}
                <ListItem className={classes.listItem}>
                  {isDesktop ? (
                    <>
                      <Button
                        disableRipple
                        component={Link}
                        className={classes.navLink +
                            (pathName === "/san-pham-giai-phap" || pathName === "/du-bao"
                            || pathName === "/weather-pro" || pathName === "/api-thoi-tiet"? " "+ classes.navLinkActive:"")}
                        color="transparent"
                        onClick={handleClickProductMenu}
                      >
                        {t("Products")}
                      </Button>
                      <MenuMaterial
                        id="product-menu"
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseProductMenu}
                        className={classes.productMenu}
                        TransitionComponent={Fade}
                      >
                        <div className={classes.containerMenu}>
                          <GridContainer className={classes.contentCommonMenu}>
                            <GridItem xs={2} className={classes.titleMainMenu}>
                              Sản phẩm
                            </GridItem>
                            <GridItem xs={2} className={classes.subTitleMainMenu}>
                              <MenuItem onClick={handleCloseProductMenu}>
                                <Button
                                    disableRipple
                                    component={Link}
                                    to="/san-pham-giai-phap"
                                    className={
                                        classes.navLink +
                                        " " +
                                        classes.subMegaMenu+ " "+classes.subMegaMenuImportant
                                    }
                                    color="transparent"
                                >
                                  Cảnh báo
                                </Button>
                              </MenuItem>
                              <MenuItem onClick={handleCloseProductMenu}>
                                <Button
                                    disableRipple
                                    component={Link}
                                    to="/san-pham-giai-phap"
                                    className={
                                        classes.navLink +
                                        " " +
                                        classes.subMegaMenu
                                    }
                                    color="transparent"
                                >
                                  Cảnh báo sét
                                </Button>
                              </MenuItem>
                              <MenuItem onClick={handleCloseProductMenu}>
                                <Button
                                    disableRipple
                                    component={Link}
                                    to="/san-pham-giai-phap"
                                    className={
                                        classes.navLink +
                                        " " +
                                        classes.subMegaMenu
                                    }
                                    color="transparent"
                                >
                                  Cảnh báo mưa dông
                                </Button>
                              </MenuItem>
                              <MenuItem onClick={handleCloseProductMenu}>
                                <Button
                                    disableRipple
                                    component={Link}
                                    to="/san-pham-giai-phap"
                                    className={
                                        classes.navLink +
                                        " " +
                                        classes.subMegaMenu
                                    }
                                    color="transparent"
                                >
                                  Cảnh báo mưa đá
                                </Button>
                              </MenuItem>
                            </GridItem>
                            <GridItem xs={3} className={classes.subTitleMainMenu}>
                              <MenuItem onClick={handleCloseProductMenu}>
                                <Button
                                    disableRipple
                                    component={Link}
                                    to="/du-bao"
                                    className={
                                        classes.navLink +
                                        " " +
                                        classes.subMegaMenu+ " "+classes.subMegaMenuImportant
                                    }
                                    color="transparent"
                                >
                                  Dự báo
                                </Button>
                              </MenuItem>
                              <MenuItem onClick={handleCloseProductMenu}>
                                <Button
                                    disableRipple
                                    component={Link}
                                    to="/du-bao"
                                    className={
                                        classes.navLink +
                                        " " +
                                        classes.subMegaMenu
                                    }
                                    color="transparent"
                                >
                                  Dự báo thời tiết 3-14 ngày
                                </Button>
                              </MenuItem>
                              <MenuItem onClick={handleCloseProductMenu}>
                                <Button
                                    disableRipple
                                    component={Link}
                                    to="/du-bao"
                                    className={
                                        classes.navLink +
                                        " " +
                                        classes.subMegaMenu
                                    }
                                    color="transparent"
                                >
                                  Dự báo dài hạn 30 ngày
                                </Button>
                              </MenuItem>
                              <MenuItem onClick={handleCloseProductMenu}>
                                <Button
                                    disableRipple
                                    component={Link}
                                    to="/du-bao"
                                    className={
                                        classes.navLink +
                                        " " +
                                        classes.subMegaMenu
                                    }
                                    color="transparent"
                                >
                                  Dự báo 3-6 tháng
                                </Button>
                              </MenuItem>
                              <MenuItem onClick={handleCloseProductMenu}>
                                <Button
                                    disableRipple
                                    component={Link}
                                    to="/du-bao"
                                    className={
                                        classes.navLink +
                                        " " +
                                        classes.subMegaMenu
                                    }
                                    color="transparent"
                                >
                                  Dự báo sóng gió, gió ngoài khơi
                                </Button>
                              </MenuItem>
                            </GridItem>
                            <GridItem xs={5} className={classes.subTitleMainMenu}>
                              <MenuItem onClick={handleCloseProductMenu}>
                                <Button
                                    disableRipple
                                    component={Link}
                                    to="/weather-pro"
                                    className={
                                        classes.navLink +
                                        " " +
                                        classes.subMegaMenu+ " "+classes.subMegaMenuImportant
                                    }
                                    color="transparent"
                                >
                                  Sản phẩm chuyên sâu
                                </Button>
                              </MenuItem>
                              <MenuItem onClick={handleCloseProductMenu}>
                                <Button
                                    disableRipple
                                    component={Link}
                                    to="/weather-pro"
                                    className={
                                        classes.navLink +
                                        " " +
                                        classes.subMegaMenu
                                    }
                                    color="transparent"
                                >
                                 <span className={classes.textImportant}>Weather Pro</span>  - Gói tư vấn thời tiết chuyên sâu
                                </Button>
                              </MenuItem>
                              <MenuItem onClick={handleCloseProductMenu}>
                                <Button
                                    disableRipple
                                    component={Link}
                                    to="/api-thoi-tiet"
                                    className={
                                        classes.navLink +
                                        " " +
                                        classes.subMegaMenu
                                    }
                                    color="transparent"
                                >
                                  <span className={classes.textImportant}>API</span> - tích hợp dữ liệu linh hoạt
                                </Button>
                              </MenuItem>
                              <MenuItem onClick={handleCloseProductMenu}>
                                  <Button
                                      disableRipple
                                      component={Link}
                                      className={
                                          classes.navLink +
                                          " " +
                                          classes.subMegaMenu
                                      }
                                      color="transparent"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        window.open("https://metplus.vn/","_blank")
                                      }}
                                  >
                                    <span className={classes.textImportant}>Metplus </span>
                                     - Nền tảng khuyến nghị hành động
                                  </Button>
                              </MenuItem>
                              <MenuItem onClick={handleCloseProductMenu}>
                                <Button
                                    disableRipple
                                    component={Link}
                                    className={
                                        classes.navLink +
                                        " " +
                                        classes.subMegaMenu
                                    }
                                    color="transparent"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      window.open("https://mobiagri.vn/","_blank")
                                    }}
                                >
                                  <span className={classes.textImportant}>mobiAgri </span>
                                  - Sản phẩm chuyển đổi số Nông nghiệp tin cậy
                                </Button>
                              </MenuItem>
                              <MenuItem onClick={handleCloseProductMenu}>
                                <Button
                                    disableRipple
                                    component={Link}
                                    className={
                                        classes.navLink +
                                        " " +
                                        classes.subMegaMenu
                                    }
                                    color="transparent"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      window.open("https://seho.vn/","_blank")
                                    }}
                                >
                                  <span className={classes.textImportant}>Seho </span>
                                  - Giải pháp vận hành an toàn, tối ưu hồ đập
                                </Button>
                              </MenuItem>
                              {/*<MenuItem onClick={handleCloseProductMenu}>*/}
                              {/*  <Button*/}
                              {/*      disableRipple*/}
                              {/*      component={Link}*/}
                              {/*      to="/data-suites"*/}
                              {/*      className={*/}
                              {/*          classes.navLink +*/}
                              {/*          " " +*/}
                              {/*          classes.subMegaMenu*/}
                              {/*      }*/}
                              {/*      color="transparent"*/}
                              {/*  >*/}
                              {/*    <span className={classes.textImportant}>Data suite</span>*/}
                              {/*     - Dữ liệu quá khứ*/}
                              {/*  </Button>*/}
                              {/*</MenuItem>*/}
                            </GridItem>
                          </GridContainer>
                        </div>
                      </MenuMaterial>
                    </>
                  ) : (
                    <GridContainer>
                      <GridItem xs={10}>
                        <Button
                          disableRipple
                          component={Link}
                          // to="/san-pham-giai-phap"
                          className={classes.navLink +
                              (pathName === "/san-pham-giai-phap" || pathName === "/du-bao"
                              || pathName === "/weather-pro" || pathName === "/api-thoi-tiet"? " "+ classes.navLinkActive:"")}
                          color="transparent"
                          style={{ paddingRight: "0px" }}
                          onClick={handleClickMenuMobile}
                        >
                          {t("Products")}
                        </Button>
                        <Collapse
                          in={openMenu}
                          timeout={"auto"}
                          unmountOnExit
                          className={classes.detailMenuMobile}
                        >
                          <ListMaterial component={"div"} disablePadding>
                            <Link
                              to={"/san-pham-giai-phap"}
                              onClick={handleClickMenuMobile}
                            >
                              <ListItemButton sx={{ pl: 4 }}>
                                <ListItemText
                                  primary={"Cảnh báo"}
                                  onClick={handleClickButton}
                                />
                              </ListItemButton>
                            </Link>
                            <Link
                                to="/du-bao"
                              onClick={handleClickMenuMobile}
                            >
                              <ListItemButton sx={{ pl: 4 }}>
                                <ListItemText
                                  primary={"Dự báo"}
                                  onClick={handleClickButton}
                                />
                              </ListItemButton>
                            </Link>
                            <Link
                              to={"/weather-pro"}
                              onClick={handleClickMenuMobile}
                            >
                              <ListItemButton sx={{ pl: 4 }}>
                                <ListItemText
                                  primary={"Weather Pro"}
                                  onClick={handleClickButton}
                                />
                              </ListItemButton>
                            </Link>
                            <Link
                              to={"/api-thoi-tiet"}
                              onClick={handleDataPastMobile}
                            >
                              <ListItemButton sx={{ pl: 4 }}>
                                <ListItemText
                                  primary={"API"}
                                  onClick={handleClickButton}
                                />
                              </ListItemButton>
                            </Link>
                            <a
                                href={"https://metplus.vn/"} target={"_blank"}  rel={"noopener noreferrer"}
                                onClick={handleClickButton}
                            >
                              <ListItemButton sx={{ pl: 4 }}>
                                <ListItemText
                                  primary={"Metplus"}
                                  onClick={handleClickButton}
                                />
                              </ListItemButton>
                            </a>
                            <a
                                href={"https://mobiagri.vn/"} target={"_blank"}  rel={"noopener noreferrer"}
                                onClick={handleClickButton}
                            >
                              <ListItemButton sx={{ pl: 4 }}>
                                <ListItemText
                                    primary={"mobiAgri"}
                                    onClick={handleClickButton}
                                />
                              </ListItemButton>
                            </a>
                            <a
                                href={"https://seho.vn/"} target={"_blank"}  rel={"noopener noreferrer"}
                                onClick={handleClickButton}
                            >
                              <ListItemButton sx={{ pl: 4 }}>
                                <ListItemText
                                    primary={"Seho"}
                                    onClick={handleClickButton}
                                />
                              </ListItemButton>
                            </a>
                            {/*<Link*/}
                            {/*    to="/data-suites"*/}
                            {/*    onClick={handleClickButton}*/}
                            {/*>*/}
                            {/*  <ListItemButton sx={{ pl: 4 }}>*/}
                            {/*    <ListItemText*/}
                            {/*        primary={"Data Suite"}*/}
                            {/*        onClick={handleClickButton}*/}
                            {/*    />*/}
                            {/*  </ListItemButton>*/}
                            {/*</Link>*/}
                          </ListMaterial>
                        </Collapse>
                      </GridItem>
                      <GridItem xs={2}>
                        <ListItemButton
                          onClick={handleClickMenuMobile}
                          className={classes.iconMenuMobile}
                        >
                          {/*<ListItemText primary={"Metplus"} />*/}
                          {openMenu ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                      </GridItem>
                    </GridContainer>
                  )}
                </ListItem>
                <ListItem className={classes.listItem}>
                  {isDesktop ? (
                    <>
                      <Button
                        disableRipple
                        component={Link}
                        className={classes.navLink + (pathName.search("/thoi-tiet-theo-nganh") !== -1 ? " "+ classes.navLinkActive:"")}
                        to={"/thoi-tiet-theo-nganh"}
                        color="transparent"
                        // onClick={handleClickButton}
                        // onClick={handleClickWeatherCareerMenu}
                      >
                        {t("WeatherCareer")}
                        {/**/}
                      </Button>
                    </>
                  ) : (
                    <GridContainer>
                      <GridItem xs={12}>
                        <Button
                          disableRipple
                          component={Link}
                          to="/thoi-tiet-theo-nganh"
                          className={classes.navLink + (pathName.search("/thoi-tiet-theo-nganh") !== -1 ? " "+ classes.navLinkActive:"")}
                          color="transparent"
                          style={{ paddingRight: "0px" }}
                          onClick={handleClickButton}
                        >
                          {t("WeatherCareer")}
                        </Button>
                      </GridItem>
                    </GridContainer>)}
                </ListItem>

                <ListItem className={classes.listItem}>
                  {isDesktop? (
                      <>
                        <Button
                          disableRipple
                          component={Link}
                          className={
                            classes.navLink +
                              (pathName === "/tin-bao" || pathName === "/tin-thoi-tiet"
                              || pathName === "/khi-hau-viet-nam" || pathName === "/thoi-tiet-cho-doanh-nghiep"
                              || pathName === "/thoi-tiet-va-doi-song"? " "+ classes.navLinkActive:"")
                                    }
                          color={"transparent"}
                          onClick={handleShowMenuNewsDesktop}
                        >
                          {t("NewsWeather")}
                        </Button>
                        <MenuMaterial
                            keepMounted
                            open={Boolean(anchorNews)}
                            onClose={handleCloseMenuNewsDesktop}
                            className={classes.productMenu}
                            TransitionComponent={Fade}
                        >
                          <div className={classes.containerMenu}>
                            <GridContainer className={classes.contentCommonMenu}>
                              <GridItem xs={3} className={classes.titleMainMenu}>
                                Tin tức thời tiết
                              </GridItem>
                              <GridItem xs={3} className={classes.subTitleMainMenu}>
                                <MenuItem onClick={handleCloseMenuNewsDesktop}>
                                  <Button
                                      disableRipple
                                      component={Link}
                                      to="/tin-bao"
                                      className={
                                          classes.navLink +
                                          " " +
                                          classes.subMegaMenu
                                      }
                                      color="transparent"
                                  >
                                      Tin bão
                                  </Button>
                                </MenuItem>
                                <MenuItem onClick={handleCloseMenuNewsDesktop}>
                                  <Button
                                      disableRipple
                                      component={Link}
                                      to="/tin-thoi-tiet"
                                      className={
                                          classes.navLink +
                                          " " +
                                          classes.subMegaMenu
                                      }
                                      color="transparent"
                                  >
                                    Tin thời tiết
                                  </Button>
                                </MenuItem>
                                <MenuItem onClick={handleCloseMenuNewsDesktop}>
                                  <Button
                                      disableRipple
                                      component={Link}
                                      to="/khi-hau-viet-nam"
                                      className={
                                          classes.navLink +
                                          " " +
                                          classes.subMegaMenu
                                      }
                                      color="transparent"
                                  >
                                    Khí hậu Việt Nam
                                  </Button>
                                </MenuItem>
                              </GridItem>
                              <GridItem xs={3} className={classes.subTitleMainMenu}>
                                <MenuItem onClick={handleCloseMenuNewsDesktop}>
                                  <Button
                                      disableRipple
                                      component={Link}
                                      to="/thoi-tiet-cho-doanh-nghiep"
                                      className={
                                          classes.navLink +
                                          " " +
                                          classes.subMegaMenu
                                      }
                                      color="transparent"
                                  >
                                    Thời tiết doanh nghiệp
                                  </Button>
                                </MenuItem>
                                <MenuItem onClick={handleCloseMenuNewsDesktop}>
                                  <Button
                                      disableRipple
                                      component={Link}
                                      to="/thoi-tiet-va-doi-song"
                                      className={
                                          classes.navLink +
                                          " " +
                                          classes.subMegaMenu
                                      }
                                      color="transparent"
                                  >
                                    Thời tiết và đời sống
                                  </Button>
                                </MenuItem>
                              </GridItem>
                            </GridContainer>
                          </div>
                        </MenuMaterial>
                      </>
                  ):
                      <GridContainer>
                        <GridItem xs={10}>
                          <Button
                              disableRipple
                              component={Link}
                              className={
                                  classes.navLink +
                                  (pathName === "/tin-bao" || pathName === "/tin-thoi-tiet"
                                  || pathName === "/khi-hau-viet-nam" || pathName === "/thoi-tiet-cho-doanh-nghiep"
                                  || pathName === "/thoi-tiet-va-doi-song"? " "+ classes.navLinkActive:"")
                              }
                              color="transparent"
                              style={{ paddingRight: "0px" }}
                              onClick={handleShowMenuNewsMobile}
                          >
                            {t("NewsWeather")}
                          </Button>
                          <Collapse
                              in={checkMenuNews}
                              timeout={"auto"}
                              unmountOnExit
                              className={classes.detailMenuMobile}
                          >
                            <ListMaterial component={"div"} disablePadding>
                              <Link
                                  to={"/tin-bao"}
                                  onClick={handleShowMenuNewsMobile}
                              >
                                <ListItemButton sx={{ pl: 4 }}>
                                  <ListItemText
                                      primary={"Tin bão"}
                                      onClick={handleClickButton}
                                  />
                                </ListItemButton>
                              </Link>
                              <Link
                                  to={"/tin-thoi-tiet"}
                                  onClick={handleShowMenuNewsMobile}
                              >
                                <ListItemButton sx={{ pl: 4 }}>
                                  <ListItemText
                                      primary={"Tin thời tiết"}
                                      onClick={handleClickButton}
                                  />
                                </ListItemButton>
                              </Link>
                              <Link
                                  to={"/khi-hau-viet-nam"}
                                  onClick={handleShowMenuNewsMobile}
                              >
                                <ListItemButton sx={{ pl: 4 }}>
                                  <ListItemText
                                      primary={"Khí hậu Việt Nam"}
                                      onClick={handleClickButton}
                                  />
                                </ListItemButton>
                              </Link>
                              <Link
                                  to={"/thoi-tiet-cho-doanh-nghiep"}
                                  onClick={handleShowMenuNewsMobile}
                              >
                                <ListItemButton sx={{ pl: 4 }}>
                                  <ListItemText
                                      primary={"Thời tiết doanh nghiệp"}
                                      onClick={handleClickButton}
                                  />
                                </ListItemButton>
                              </Link>
                              <Link
                                  to={"/thoi-tiet-va-doi-song"}
                                  onClick={handleShowMenuNewsMobile}
                              >
                                <ListItemButton sx={{ pl: 4 }}>
                                  <ListItemText
                                      primary={"Thời tiết và đời sống"}
                                      onClick={handleClickButton}
                                  />
                                </ListItemButton>
                              </Link>
                            </ListMaterial>
                          </Collapse>
                        </GridItem>
                        <GridItem xs={2}>
                          <ListItemButton
                              onClick={handleShowMenuNewsMobile}
                              className={classes.iconMenuMobile}
                          >
                            {/*<ListItemText primary={"Metplus"} />*/}
                            {checkMenuNews ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                        </GridItem>
                      </GridContainer>
                  }
                </ListItem>
                <ListItem className={classes.listItem}>
                  {isDesktop ? (
                      <>
                        <Button
                            disableRipple
                            component={Link}
                            className={classes.navLink + (pathName.search("/ve-chung-toi") !== -1? " " + classes.navLinkActive:"")}
                            color="transparent"
                            onClick={handleClickAbout}
                        >
                          {t("About")}
                        </Button>
                        <MenuMaterial
                            // id="product-menu"
                            keepMounted
                            open={Boolean(aboutAnchorEl)}
                            onClose={handleCloseAbout}
                            className={classes.productMenu}
                            TransitionComponent={Fade}
                        >
                          <div className={classes.containerMenu}>
                            <GridContainer className={classes.contentCommonMenu}>
                              <GridItem xs={3} className={classes.titleMainMenu}>
                                Về chúng tôi
                              </GridItem>
                              <GridItem xs={3} className={classes.subTitleMainMenu}>
                                <MenuItem onClick={handleCloseAbout}>
                                  <Button
                                      disableRipple
                                      component={Link}
                                       to="/ve-chung-toi/gioi-thieu-cong-ty"
                                      className={
                                          classes.navLink +
                                          " " +
                                          classes.subMegaMenu
                                      }
                                      color="transparent"
                                  >
                                    Giới thiệu công ty
                                  </Button>
                                </MenuItem>
                                {/*<MenuItem onClick={handleCloseAbout}>*/}
                                {/*  <Button*/}
                                {/*      disableRipple*/}
                                {/*      component={Link}*/}
                                {/*      to="/ve-chung-toi/doi-ngu-lanh-dao"*/}
                                {/*      className={*/}
                                {/*          classes.navLink +*/}
                                {/*          " " +*/}
                                {/*          classes.subMegaMenu*/}
                                {/*      }*/}
                                {/*      color="transparent"*/}
                                {/*  >*/}
                                {/*    Đội ngũ lãnh đạo*/}
                                {/*  </Button>*/}
                                {/*</MenuItem>*/}
                                <MenuItem onClick={handleCloseAbout}>
                                <Button
                                      disableRipple
                                      component={Link}
                                      to="/ve-chung-toi/doi-ngu-chuyen-gia"
                                      className={
                                          classes.navLink +
                                          " " +
                                          classes.subMegaMenu
                                      }
                                      color="transparent"
                                  >
                                    Đội ngũ chuyên gia
                                  </Button>
                                </MenuItem>
                              </GridItem>
                              <GridItem xs={2} className={classes.subTitleMainMenu}>
                                <MenuItem onClick={handleCloseAbout}>
                                  <Button
                                      disableRipple
                                      component={Link}
                                      to={"/ve-chung-toi/khach-hang"}
                                      className={
                                          classes.navLink +
                                          " " +
                                          classes.subMegaMenu
                                      }
                                      color="transparent"
                                  >
                                    Khách hàng
                                  </Button>
                                </MenuItem>
                                <MenuItem onClick={handleCloseAbout}>
                                <Button
                                      disableRipple
                                      component={Link}
                                      to={"/ve-chung-toi/doi-tac"}
                                      className={
                                          classes.navLink +
                                          " " +
                                          classes.subMegaMenu
                                      }
                                      color="transparent"
                                  >
                                    Đối tác
                                  </Button>
                                </MenuItem>
                                <MenuItem onClick={handleCloseAbout}>
                                <Button
                                      disableRipple
                                      component={Link}
                                      to="/ve-chung-toi/bao-chi-viet-ve-chung-toi"
                                      className={
                                          classes.navLink +
                                          " " +
                                          classes.subMegaMenu
                                      }
                                      color="transparent"
                                  >
                                    Báo chí
                                  </Button>
                                </MenuItem>

                              </GridItem>
                              <GridItem xs={4} className={classes.subTitleMainMenu}>
                                <MenuItem onClick={handleCloseAbout}>
                                <Button
                                      disableRipple
                                      component={Link}
                                      to="/ve-chung-toi/cuoc-song-tai-weatherplus"
                                      className={
                                          classes.navLink +
                                          " " +
                                          classes.subMegaMenu
                                      }
                                      color="transparent"
                                  >
                                    Cuộc sống tại WeatherPlus
                                  </Button>
                                </MenuItem>
                                <MenuItem onClick={handleCloseAbout}>
                                <Button
                                      disableRipple
                                      component={Link}
                                      to={"/ve-chung-toi/tuyen-dung"}
                                      className={
                                          classes.navLink +
                                          " " +
                                          classes.subMegaMenu
                                      }
                                      color="transparent"
                                  >
                                    Tuyển dụng
                                  </Button>
                                </MenuItem>
                                <MenuItem onClick={handleCloseAbout}>
                                  <Button
                                      disableRipple
                                      component={Link}
                                      to="/ve-chung-toi/lien-he"
                                      className={
                                          classes.navLink +
                                          " " +
                                          classes.subMegaMenu
                                      }
                                      color="transparent"
                                  >
                                    Liên hệ
                                  </Button>
                                </MenuItem>
                              </GridItem>
                            </GridContainer>
                          </div>
                        </MenuMaterial>
                      </>
                  ) : (
                      <GridContainer>
                        <GridItem xs={10}>
                          <Button
                              disableRipple
                              component={Link}
                              // to="/san-pham-giai-phap"
                              className={classes.navLink + (pathName.search("/ve-chung-toi") !== -1? " " + classes.navLinkActive:"")}
                              color="transparent"
                              style={{ paddingRight: "0px" }}
                              onClick={handleClickMenuAboutMobile}
                          >
                            {t("About")}
                          </Button>
                          <Collapse
                              in={mobileAboutOpen}
                              timeout={"auto"}
                              unmountOnExit
                              className={classes.detailMenuMobile}
                          >
                            <ListMaterial component={"div"} disablePadding>
                              <Link
                                  to="/ve-chung-toi/gioi-thieu-cong-ty"
                                  onClick={handleClickMenuMobile}
                              >
                                <ListItemButton sx={{ pl: 4 }} onClick={handleClickMenuAboutMobile}>
                                  <ListItemText
                                      primary={"Giới thiệu công ty"}
                                      onClick={handleClickButton}
                                  />
                                </ListItemButton>
                              </Link>
                              {/*<Link*/}
                              {/*    to="/ve-chung-toi/doi-ngu-lanh-dao"*/}
                              {/*    onClick={handleClickMenuMobile}*/}
                              {/*>*/}
                              {/*  <ListItemButton sx={{ pl: 4 }} onClick={handleClickMenuAboutMobile}>*/}
                              {/*    <ListItemText*/}
                              {/*        primary={"Đội ngũ lãnh đạo"}*/}
                              {/*        onClick={handleClickButton}*/}
                              {/*    />*/}
                              {/*  </ListItemButton>*/}
                              {/*</Link>*/}
                              <Link
                                  to="/ve-chung-toi/doi-ngu-chuyen-gia"
                                  onClick={HandleCloseMenuMobileAbout}
                              >
                                <ListItemButton sx={{ pl: 4 }} onClick={handleClickMenuAboutMobile}>
                                  <ListItemText
                                      primary={"Đội ngũ chuyên gia"}
                                      onClick={handleClickButton}
                                  />
                                </ListItemButton>
                              </Link>
                              <Link
                                  to={"/ve-chung-toi/khach-hang"}
                                  // onClick={handleDataPastMobile}
                              >
                                <ListItemButton sx={{ pl: 4 }} onClick={handleClickMenuAboutMobile}>
                                  <ListItemText
                                      primary={"Khách hàng"}
                                      onClick={handleClickButton}
                                  />
                                </ListItemButton>
                              </Link>
                              <Link
                                  to="/ve-chung-toi/lien-he"
                                  onClick={HandleCloseMenuMobileAbout}
                              >
                                <ListItemButton sx={{ pl: 4 }} onClick={handleClickMenuAboutMobile}>
                                  <ListItemText
                                      primary={"Liên hệ"}
                                      onClick={handleClickButton}
                                  />
                                </ListItemButton>
                              </Link>
                              <Link
                                  to="/ve-chung-toi/doi-tac"
                                  onClick={HandleCloseMenuMobileAbout}
                              >
                                <ListItemButton sx={{ pl: 4 }} onClick={handleClickMenuAboutMobile}>
                                  <ListItemText
                                      primary={"Đối tác"}
                                      onClick={handleClickButton}
                                  />
                                </ListItemButton>
                              </Link>
                              <Link
                                  to="/ve-chung-toi/bao-chi-viet-ve-chung-toi"
                                  onClick={HandleCloseMenuMobileAbout}
                              >
                                <ListItemButton sx={{ pl: 4 }} onClick={handleClickMenuAboutMobile}>
                                  <ListItemText
                                      primary={"Báo chí"}
                                      onClick={handleClickButton}
                                  />
                                </ListItemButton>
                              </Link>
                              <Link
                                  to="/ve-chung-toi/cuoc-song-tai-weatherplus"
                                  onClick={HandleCloseMenuMobileAbout}
                              >
                                <ListItemButton sx={{ pl: 4 }} onClick={handleClickMenuAboutMobile}>
                                  <ListItemText
                                      primary={"Cuộc sống tại WeatherPlus"}
                                      onClick={handleClickButton}
                                  />
                                </ListItemButton>
                              </Link>
                              <Link
                                  to="/ve-chung-toi/tuyen-dung"
                                  onClick={HandleCloseMenuMobileAbout}
                              >
                                <ListItemButton sx={{ pl: 4 }} onClick={handleClickMenuAboutMobile}>
                                  <ListItemText
                                      primary={"Tuyển dụng"}
                                      onClick={handleClickButton}
                                  />
                                </ListItemButton>
                              </Link>
                            </ListMaterial>
                          </Collapse>
                        </GridItem>
                        <GridItem xs={2}>
                          <ListItemButton
                              onClick={handleClickMenuAboutMobile}
                              className={classes.iconMenuMobile}
                          >
                            {/*<ListItemText primary={"Metplus"} />*/}
                            {mobileAboutOpen ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                        </GridItem>
                      </GridContainer>
                  )}
                </ListItem>

                <ListItem className={classes.listItem}>
                  <div className={classes.containerSocial}>
                  <Typography className={classes.coverSocial}>
                    <div className={classes.frameSocial}>
                    <a
                        href="https://www.youtube.com/channel/UC4qb3ZpsPfcnAJGZx7AjZFw"
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                        className={classes.socialIcon}
                    >
                      <YoutubeIcon />
                    </a>
                    <a
                        href="https://www.linkedin.com/company/weatherplus"
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                        className={classes.socialIcon}
                    >
                      <LinkedinIcon />
                    </a>
                    <a
                        href="https://www.facebook.com/weatherplus"
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                        className={classes.socialIcon}
                    >
                      <FacebookIcon />
                    </a>
                    <a
                        href="mailto:kinhdoanh@weatherplus.vn"
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                        className={classes.socialIcon}
                        style={{marginRight:0}}
                    >
                      <EnvelopeIcon />
                    </a>
                    </div>
                  </Typography>
                  </div>
                </ListItem>
                {isDesktop ? (
                  ""
                ) : (
                  <ListItem
                    className={classes.listItem}
                    style={{ padding: "10px 15px 0px 15px" }}
                  >
                    <div>
                      <TemperatureUnitSwitch />
                    </div>
                  </ListItem>
                )}
                {/* <Hidden mdUp>
                  <ListItem className={classes.listItem}>
                    <Button
                      disableRipple
                      to=""
                      className={classes.navLink}
                      color="transparent"
                      onClick={() => handleLanguageChanged(Languages.vi)}
                    >
                      {t("Vietnamese")}
                    </Button>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <Button
                      disableRipple
                      to=""
                      className={classes.navLink}
                      color="transparent"
                      onClick={() => handleLanguageChanged(Languages.en)}
                    >
                      {t("English")}
                    </Button>
                  </ListItem>
                </Hidden> */}
              </List>
              {/* <Hidden smDown>
              <List className={classes.list + " " + classes.mlAuto}>
                <ListItem className={classes.listItem}>
                  <IconButton
                    aria-label="Choose language"
                    color="inherit"
                    onClick={handleLanguageClicked}
                  >
                    <Language />
                  </IconButton>
                  {languageDialog && (
                    <LanguageDialog
                      onClose={handleClose}
                      onChange={handleLanguageChanged}
                    />
                  )}
                </ListItem>
              </List>
            </Hidden> */}
            </div>
          }
        />
      )}
      {/*<UserFormInput*/}
      {/*  isVisiableModal={isVisiableModal}*/}
      {/*  handleClickCloseModal={handleClickCloseModal}*/}
      {/*  width={12}*/}
      {/*/>*/}
      <div className={classes.wrapper} ref={wrapper}>
        {/*{!isMobile?*/}
        {/*    <div className={classes.noteLink} style={pathName ==="/"? {display:"none"}:{}}>*/}
        {/*      <GridContainer className={classes.containerNoteLink}>*/}
        {/*        <GridItem xs={6} className={classes.titleLink}>*/}
        {/*          Dự báo thời tiết*/}
        {/*        </GridItem>*/}
        {/*        <GridItem xs={6} className={classes.subTitleLink}>*/}
        {/*          Trang chủ / Thời tiết & Khí hậu / Dự báo thời tiết*/}
        {/*        </GridItem>*/}
        {/*      </GridContainer>*/}
        {/*    </div>*/}
        {/*    :""*/}
        {/*}*/}
        {/*{isMobile?*/}
        {/*    <>*/}
        {/*    <div className={classes.containerPopupApp}>*/}
        {/*      <button onClick={handleOpenPopup} className={classes.buttonPopupApp}>*/}
        {/*        <img src={logoApp2} alt={"logoApp2"} className={classes.imageLogoPopupApp}/>*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*    <div className={checkPopupApp? classes.detailPopup : classes.detailPopup +" "+classes.detailPopupHidden}>*/}
        {/*        <GridContainer>*/}
        {/*            <GridItem xs={2}></GridItem>*/}
        {/*                <GridItem xs={8}className={classes.contentPopup}>*/}
        {/*                    <a href={"https://metplus.vn/install"} target={"_blank"} rel={"noopener noreferrer"}>*/}
        {/*                    <div className={classes.coverText}>*/}
        {/*                        <div className={classes.notePopup}>*/}
        {/*                            <span>Cập nhật thời tiết thường xuyên với</span>*/}
        {/*                        </div>*/}
        {/*                        <div className={classes.titlePopup}>*/}
        {/*                            <span>Ứng dụng thời tiết tin cậy Metplus</span>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                    </a>*/}
        {/*                </GridItem>*/}
        {/*                <GridItem xs={1} className={classes.contentDownload}>*/}
        {/*                    <a href={"https://metplus.vn/install"} target={"_blank"}  rel={"noopener noreferrer"}>*/}
        {/*                        <img src={ImageDownload} alt={"ImageDownload"}/>*/}
        {/*                    </a>*/}
        {/*                </GridItem>*/}
        {/*        </GridContainer>*/}
        {/*    </div>*/}
        {/*    </>*/}
        {/*    :*/}
        {/*    <>*/}
        {/*        <div className={!checkPopupApp? classes.containerPopupApp : classes.containerPopupApp +" "+classes.containerPopupAppHidden}>*/}
        {/*            <button onClick={handleOpenPopup} className={classes.buttonPopupApp} >*/}
        {/*                <img src={logoApp2} alt={"logoApp2"} className={classes.imageLogoPopupApp}/>*/}
        {/*            </button>*/}
        {/*        </div>*/}
        {/*        <div className={checkPopupApp? classes.detailPopupDesktop : classes.detailPopupDesktop+" "+classes.detailPopupDesktopHidden}>*/}
        {/*           <div className={classes.containerButtonClosePopup}>*/}
        {/*               <button className={classes.btnClosePopupDesktop} onClick={handleClosePopup}>*/}
        {/*                   <img src={ImageClose} alt={"ImageClose"} height={30} className={classes.closePopupDesktop} />*/}
        {/*               </button>*/}
        {/*           </div>*/}
        {/*            <GridContainer>*/}
        {/*                <GridItem xs={4}>*/}
        {/*                    <img src={logoApp2} alt={"logoApp"} height={85} className={classes.logoAppDetail}/>*/}
        {/*                </GridItem>*/}
        {/*                <GridItem xs={8} className={classes.textContentPopupDesktop}>*/}
        {/*                    <div>*/}
        {/*                        <div className={classes.textOfficial}>*/}
        {/*                            <span>Chính thức ra mắt</span>*/}
        {/*                        </div>*/}
        {/*                        <div className={classes.titlePopupDesktop}>*/}
        {/*                            <span>Ứng dụng dự báo thời tiết tin cậy</span>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </GridItem>*/}
        {/*                <GridItem xs={12}>*/}
        {/*                    <div className={classes.textUpdateWeather}>*/}
        {/*                        <span>Bạn cần cập nhật thời tiết thường xuyên?</span>*/}
        {/*                    </div>*/}
        {/*                    <div className={classes.textTrialMetplus}>*/}
        {/*                        <span>Thử ngay Metplus</span>*/}
        {/*                    </div>*/}
        {/*                </GridItem>*/}
        {/*                <GridItem xs={2} className={classes.containerTickRed}>*/}
        {/*                    <img src={TickRed} alt={"TickRed"} className={classes.tickRedPopupDesktop}/>*/}
        {/*                </GridItem>*/}
        {/*                <GridItem xs={10}>*/}
        {/*                    <div className={classes.textDetailPopupDesktop}>*/}
        {/*                        <span>Cập nhật thời tiết hiện tại liên tục</span>*/}
        {/*                    </div>*/}
        {/*                </GridItem>*/}
        {/*                <GridItem xs={2} className={classes.containerTickRed}>*/}
        {/*                    <img src={TickRed} alt={"TickRed"} className={classes.tickRedPopupDesktop}/>*/}
        {/*                </GridItem>*/}
        {/*                <GridItem xs={10}>*/}
        {/*                    <div className={classes.textDetailPopupDesktop}>*/}
        {/*                        <span>Cảnh báo mưa dông 3 giờ tới</span>*/}
        {/*                    </div>*/}
        {/*                </GridItem>*/}
        {/*                <GridItem xs={2} className={classes.containerTickRed}>*/}
        {/*                    <img src={TickRed} alt={"TickRed"} className={classes.tickRedPopupDesktop}/>*/}
        {/*                </GridItem>*/}
        {/*                <GridItem xs={10}>*/}
        {/*                    <div className={classes.textDetailPopupDesktop}>*/}
        {/*                        <span>Nhận khuyến nghị hành động theo thời tiết</span>*/}
        {/*                    </div>*/}
        {/*                </GridItem>*/}
        {/*                <GridItem xs={7} className={classes.containerBtnDownloadDesktop}>*/}
        {/*                    <button className={classes.btnDownloadDesktop}>*/}
        {/*                        <a href={"https://metplus.vn/install"} target={"_blank"}  rel={"noopener noreferrer"}>*/}
        {/*                        <GridContainer>*/}
        {/*                            /!*<GridItem xs={2}></GridItem>*!/*/}
        {/*                            <GridItem xs={4} className={classes.coverIconDownload}>*/}
        {/*                                <div className={classes.containerIconDownload}>*/}
        {/*                                    <img src={IconDownload} alt={"IconDownload"} width={25}/>*/}
        {/*                                </div>*/}
        {/*                                <div className={classes.shadowTop}>*/}
        {/*                                </div>*/}
        {/*                                <div className={classes.shadowBottom}>*/}

        {/*                                </div>*/}
        {/*                            </GridItem>*/}
        {/*                            <GridItem xs={8} className={classes.textDownloadNow}>*/}
        {/*                                <span>Quét mã tải ngay</span>*/}
        {/*                            </GridItem>*/}
        {/*                        </GridContainer>*/}
        {/*                        </a>*/}
        {/*                    </button>*/}
        {/*                </GridItem>*/}
        {/*                <GridItem xs={5} className={classes.containerQRApp}>*/}
        {/*                    <img src={QR_App} alt={"QR_App"}/>*/}
        {/*                </GridItem>*/}
        {/*            </GridContainer>*/}
        {/*        </div>*/}
        {/*    </>*/}
        {/*        }*/}
          <div className={classes.fullPage}>
            {props.children}
          </div>
      </div>
      {layout.footer && <Footer />}
    </div>
  );
};

AuthLayout.displayName = "AuthLayout";

AuthLayout.propTypes = { children: PropTypes.node.isRequired };

// export default withReducer("news",reducer)(AuthLayout);

export default AuthLayout;
