import { combineReducers } from "redux";
import { createMigrate, persistReducer } from "redux-persist";
import localForage from "localforage";

import home from "app/pages/home/store/reducers";
// import weather from "app/pages/weather/store/reducers";
// import news from "app/pages/news/store/reducers";
import settingsReducer, { migrations } from "./settings.reducer";
import recentNewsReducer from "./recentnews.reducer";
import businessNewsReducer from "./businessnews.reducer";
import auth from "app/auth/store/reducers";

const MIGRATION_DEBUG = false;

const settingsPersistConfig = {
  key: "settingsStore",
  version: 0,
  storage: localForage,
  migrate: createMigrate(migrations, { debug: MIGRATION_DEBUG }),
};

const createReducer = (asyncReducers) =>
  combineReducers({
    auth,
    home,
    // weather,
    // news,
    recentNews: recentNewsReducer,
    businessNews: businessNewsReducer,
    settings: persistReducer(settingsPersistConfig, settingsReducer),
    ...asyncReducers,
  });

export default createReducer;
