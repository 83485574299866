import React, { useCallback } from "react";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { TemperatureUnits } from "app/values/weather";

import * as Actions from "app/store/actions/settings.action";

const UnitSwitch = withStyles((theme) => ({
  root: {
    width: 88,
    height: 40,
    padding: 0,
    overflow: "visible",
    [theme.breakpoints.down("xs")]: {
      width: 70,
      height: 36,
    },
  },
  switchBase: {
    padding: 0,
    top: 4,
    left: 4,
    "&$checked": {
      transform: "translateX(48px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: "none",
      },
      "&:hover + $track": {
        backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.down("xs")]: {
        transform: "translateX(34px)",
      },
    },
    "&:hover + $track": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  thumb: {
    width: 32,
    height: 32,
    fontWeight: "bold",
    fontSize: 18,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white,
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow:
      "10px 10px 20px 0 rgb(69 61 141 / 25%), -10px -10px 20px 0 #e9e8f58c",
    [theme.breakpoints.down("xs")]: {
      width: 28,
      height: 28,
    },
  },
  track: {
    borderRadius: 36,
    border: "none",
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
    boxShadow:
      "10px 10px 20px 0 rgb(69 61 141 / 25%), -10px -10px 20px 0 #e9e8f58c",
    "&::before": {
      position: "absolute",
      top: 4,
      left: 8,
      content: '"°F"',
      fontWeight: "bold",
      fontSize: 16,
      color: theme.palette.primary.contrastText,
      width: 32,
      height: 32,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        width: 28,
        height: 28,
        fontSize: 14,
      },
    },
    "&::after": {
      position: "absolute",
      top: 4,
      right: 8,
      content: '"°C"',
      fontWeight: "bold",
      fontSize: 16,
      color: theme.palette.primary.contrastText,
      width: 32,
      height: 32,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        width: 28,
        height: 28,
        fontSize: 14,
      },
    },
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const TemperatureUnitSwitch = () => {
  const dispatch = useDispatch();
  const unit = useSelector(({ settings }) => settings.temperatureUnit);

  const handleUnitChange = useCallback(
    (evt) => {
      dispatch(
        Actions.setTemperatureUnit(
          evt.target.checked ? TemperatureUnits.f : TemperatureUnits.c
        )
      );
    },
    [dispatch]
  );

  return (
    <UnitSwitch
      checked={unit === TemperatureUnits.f}
      onChange={handleUnitChange}
    ></UnitSwitch>
  );
};

export default TemperatureUnitSwitch;
