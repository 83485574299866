import axios from "axios";
import { wpDomainMetplus } from "app/configs/wp.config";
import history from "@history";
// import apiConfig from "app/configs/api.config";

const wpApi = axios.create({
  baseURL: `${wpDomainMetplus}/api`,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
  },
  withCredentials: true,
});
// const wpDevApi = axios.create({
//   baseURL:'https://api-gateway-dev.weatherplus.vn/metplus/api',
//   headers: {
//       "X-Requested-With": "XMLHttpRequest",
//     "Content-Type": "application/json",
//       // "Access-Control-Allow-Origin": "*"
//   },
//   withCredentials:true,
// })

export const callApiBackendNoAuth = async (uri, payload) => {
  const res = await wpApi
    .post(uri, payload)
    .then((response) => {
      if (response.status === 401) {
        history.replace("/");
      }
      return response;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        history.replace("/");
      }
      return error.response;
    });
  return res;
};
//
// export const callApiHooksSlack = async (uri,payload) => {
//   const res = await slackApi
//       .post(uri,payload)
//       .then((response) => {
//         if (response.status === 401) {
//           history.replace("/");
//         }
//         return response;
//       })
//       .catch((error) => {
//         if (error.response.status === 401) {
//           history.replace("/");
//         }
//         return error.response;
//       });
//   return res
// }

export default {
  callApiBackendNoAuth,
    // callApiHooksSlack
};
