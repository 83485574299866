const locale = {
  "Login": "Login",
  "Register": "Sign In",
  "Feature": "Tính năng dịch vụ",
  "Text Feature1 Title": "Độ chính xác cao",
  "Text Feature1 Content": "Dự báo sử dụng công nghệ Nowcast, theo dõi khả năng hình thành các trận mưa lớn, xảy ra đột ngột",
  "Text Feature2 Title": "Giải pháp tiện lợi",
  "Text Feature2 Content": "Happy Rain sẽ thay bạn liên tục cập nhật cho bạn các cảnh báo mưa lớn mới nhất qua email, SMS",
  "Text Feature3 Title": "Giúp bạn tự chủ hơn cho sinh hoạt và làm việc",
  "Text Feature3 Content": "Happy Rain sẽ giúp bạn tự chủ hơn trong việc đi lại cũng như các công việc hằng ngày",
  "Text Feature4 Title": "Chi phí hợp lý, chỉ từ 9,000đ/tháng",
  "Text Feature4 Content": "Chỉ từ 9,000đ/tháng, bạn hoàn toàn có thể yên tâm khỏi những rủi ro do mưa lớn gây ra",
  Msisdn: "Số điện thoại",
  "Register Service": "Đăng ký dịch vụ",
  "Account Info Title": "Thông tin tài khoản",
  Email: "Email",
  ValidNumber: "Chỉ được nhập số",
  ValidMaxLength: "Nhập tối đa {0} ký tự",
  ValidNotNumber: "Chỉ được nhập số",
  Confirm: "Xác nhận",
  "Require Input": "Vui lòng nhập {0} ",
  ValidPatternEmail: "Sai định dạng email",
  "Service Package": "Gói dịch vụ",
  "Location Alert": "Vị trí đăng kí nhận cảnh báo",
  "Review Name1": "Lê Anh Tuấn",
  "Review Job1": "Lái xe",
  "Review Content1":
    "Dân đi xe ô tô thường ngán nhất vụ ngập úng, vừa bẩn xe mà lại có khi chết máy. Kể từ đợt đăng ký Happy Rain thì đỡ hẳn, biết mưa nên cũng tránh ra đường luôn",
  "Review Name2": "Trần Tâm Anh",
  "Review Job2": "Nhân viên văn phòng",
  "Review Content2":
    "Biết Sài Gòn rất hay mưa bất chợt, nhưng đi công chuyện bên ngoài mà gặp mưa thì cũng bất tiện lắm. Cũng may giờ có cả cảnh báo mưa, mình cũng chủ động hơn nhiều.",
  "Review Name3": "Trịnh Tâm Lương",
  "Review Job3": "Nhiếp ảnh gia",
  "Review Content3":
    "Làm nghề chụp ảnh sự kiện, ảnh cưới ngoài trời, tôi không thể làm việc nếu thời tiết xấu. Happy Rain giúp tôi sắp xếp lịch chụp ảnh hợp lý mà không lo bị gián đoạn bởi mưa. Cảm ơn Happy Rain.",
  "Register Now": "Đăng ký ngay",
  "Package Title Choose": "Gói Sản Phẩm",
  Payment: "Thanh toán",
  "Order Info": "Thông tin đơn hàng",
  "Location Ranking": "Địa điểm {0}",
  "Total Money": "Tổng Tiền",
  "Update Order": "Chỉnh sửa đơn hàng",
  "Payment Method": "Chọn phương thức thanh toán",
  "Not Msisdn": "Sai định dạng số điện thoại",
  "Happy Rain": "HAPPY RAIN",
  "Forget Password": "Quên mật khẩu?",
  Password: "Mật khẩu",
  "Invite Register": "Bạn chưa có tài khoản? Mời bạn đăng kí tại đây",
  "Invalid Login": "Tài khoản hoặc mật khẩu không đúng",
  Profile: "Thông tin tài khoản",
  Account: "Tài khoản",
  "Expire Date": "Ngày hết hạn",
  "Time Send Warning": "Thời gian gửi cảnh báo 24/7",
  "24/7": "24/7",
  Feedback: "Góp ý cho sản phẩm",
  "Change Password": "Thay đổi mật khẩu",
  "Change Location": "Thay đổi/thêm vị trí cảnh báo",
  Save: "Lưu",
  "Result Success": "Thực hiện {0} thành công!",
  "Result Failure": "Thực hiện {0} thất bại!",
  "Choose Title Feedback": "Lựa chọn phản hồi",
  "Current Password": "Mật khẩu hiện tại",
  "New Password": "Mật khẩu mới",
  "Confirm Password": "Nhập lại mật khẩu mới",
  "Not Use Old Password": "Không sử dụng mật khẩu cũ",
  "Not Match Password": "Mật khẩu xác nhận không trùng khớp",
  "Logout": "Đăng xuất",
  "Confirm OTP": "Vui lòng nhập mã xác nhận được gửi tới số điện thoại của bạn",
  "Input Confirm OTP": "Vui lòng nhập mã xác nhận",
  "Invalid Format Msisdn": "Định dạng số điện thoại chưa đúng",
  "Create Password Success": "Mật khẩu mới đã được gửi tới số điện thoại của bạn. Vui lòng kiểm tra tin nhắn",
  "Register Success": "Chúc mừng quý khách đã đăng ký thành công",
};

export default locale;
