import { grayColor, mlAuto } from "assets/jss/weatherplus.js";

const headerLinksStyle = (theme) => ({
  list: {
    [theme.breakpoints.up("md")]: {
      WebkitBoxAlign: "center",
      MsFlexAlign: "center",
      alignItems: "center",
      WebkitBoxOrient: "horizontal",
      WebkitBoxDirection: "normal",
      MsFlexDirection: "row",
      flexDirection: "row",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    marginTop: "0px",
    display: "flex",
    paddingLeft: "0",
    marginBottom: "0",
    listStyle: "none",
    padding: "0",
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "flex",
    justifyContent:"center",
    alignItems: "center",
    width: "auto",
    margin: "0",
    padding: "0",
    height:"100%",
    "&:hover,&:focus,&:active": {
      borderBottom: "2px solid #64AF02"
    },
    [theme.breakpoints.down("sm")]: {
      display:"block",
      justifyContent:"unset",
      alignItems: "unset",
      height:"unset",
      "&:hover,&:focus,&:active": {
        borderBottom: "unset"
      },
      "& ul": {
        maxHeight: "400px",
        overflow: "scroll",
      },
      width: "100%",
      "&:not(:last-child)": {
        "&:after": {
          width: "calc(100% - 20px)",
          content: '""',
          display: "block",
          height: "1px",
          marginLeft: "15px",
          backgroundColor: grayColor[14],
        },
      },
    },
  },
  listItemText: {
    padding: "0 !important",
  },
  navLink: {
    fontFamily:"Roboto",
    color: "#000000",
    position: "relative",
    padding: "13px 14px",
    fontWeight: "400",
    fontSize: "20px",
    textTransform: "initial",
    lineHeight: 1.12,
    letterSpacing: "-0.03em",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: theme.palette.primary.main,
    },
    "&:active": {
      color: theme.palette.primary.main,
    },
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      position: "relative",
      top: "2px",
      marginTop: "-4px",
      marginRight: "4px",
      marginBottom: "0px",
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
    },
    "& svg": {
      marginRight: "3px",
      width: "24px",
      height: "24px",
    },
  },
  navLinkActive:{
    fontFamily:"Roboto",
    color: theme.palette.primary.main,
    position: "relative",
    padding: "13px 14px",
    fontWeight: "400",
    fontSize: "22px",
    textTransform: "initial",
    lineHeight: 1.12,
    letterSpacing: "-0.03em",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
  },
  collapse: {
    [theme.breakpoints.up("md")]: {
      // display: "flex !important",
      MsFlexPreferredSize: "auto",
      // flexBasis: "auto",
    },
    WebkitBoxFlex: "1",
    MsFlexPositive: "1",
    flexGrow: "1",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  mlAuto,
  brandImage: {
    height: 70,
    // marginTop: 10,
    marginRight: 75,
    paddingLeft: 0,
    marginTop:4,
    [theme.breakpoints.down("md")]: {
      marginRight: 40,
      paddingLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      height: 55,
      marginRight: 40,
      paddingLeft: 0,
    },
  },
  megaMenu: {
    marginTop: 65,
  },
  containerMenu:{
    display:"flex",
    justifyContent:"center"
  },
  contentCommonMenu:{
    maxWidth: 1355,
    width:"100%",
    borderTop:"1px solid #D7D7D7"
  },
  titleMainMenu:{
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: 40,
    lineHeight: "72px",
    color: "#000000",
    display:'flex',
    alignItems:"center",
    justifyContent:"center"
  },
  subTitleMainMenu:{
    borderLeft:"1px solid rgba(145, 145, 145, 0.5)"
  },
  noteLink:{
    background: "#E9EAEC",
    width:"100%",
    display:"flex",
    justifyContent:"center",
    paddingTop:"69px"
  },
  containerNoteLink:{
    maxWidth:1355,
    width:"100%",
  },
  forecastMenu: {
    "& .MuiPaper-root": {
      top: "68px !important",
      left: "0px !important",
      // borderRadius: "8px",
      width:"100%",
      maxWidth:"100%",
      background:"rgba(255, 255, 255, 0.95)",
      backdropFilter:"blur(50px)",
      boxShadow:"none"
    },
    "& .MuiMenu-list":{
      padding:"0px"
    },
    "& a": {
      color: "inherit",
      "&:hover,&:focus": {
        color: theme.palette.primary.main,
      },
    },
    "& .MuiList-root": {
      "& .MuiMenuItem-root": {
        color: "inherit",
      },
    },
  },
  productMenu: {
    "& .MuiPaper-root": {
      top: "68px !important",
      left: "0px !important",
      // borderRadius: "8px",
      width:"100%",
      maxWidth:"100%",
      background:"rgba(255, 255, 255, 0.95)",
      backdropFilter:"blur(50px)",
      boxShadow:"none"
    },
    "& .MuiMenu-list":{
      padding:"0px"
    },
    "& a": {
      color: "inherit",
      "&:hover,&:focus": {
        color: theme.palette.primary.main,
      },
    },
    "& .MuiList-root": {
      "& .MuiMenuItem-root": {
        color: "inherit",
      },
    },
  },
  weatherCareerMenu: {
    // overflow: "auto",
    "& .MuiPaper-root": {
      top: "81px !important",
      left: "43% !important",
      borderRadius: "8px",
    },
    "& a": {
      color: "inherit",
      "&:hover,&:focus": {
        color: theme.palette.primary.main,
      },
    },
    "& .MuiList-root": {
      "& .MuiMenuItem-root": {
        color: "inherit",
      },
    },
  },
  noneDisplay: {
    display: "none",
  },
  boldMenu: {
    fontWeight: "900",
    borderBottom: "1px solid #d0c7c7 !important",
    borderRadius: "0",
    fontSize: "1.1rem !important",
  },
  subMegaMenu: {
    display: "block",
    textAlign: "left",
    padding: "5px 130px 5px 5px",
    // marginLeft: "15px",
    marginRight: "20px",
    width: "260px !important",
    textTransform: "none",

    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 20,
    lineHeight: "22px",
    color: "#000000",
  },
  subMegaMenuImportant:{
    display: "block",
    textAlign: "left",
    padding: "5px 130px 5px 5px",
    // marginLeft: "15px",
    marginRight: "20px",
    width: "260px !important",
    textTransform: "none",

    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 20,
    lineHeight: "22px",
    color: "#64AF02 !important",
  },
  textImportant:{
    fontWeight: "550 !important",
    textDecorationLine:"underline"
},
  iconMenu: {
    marginLeft: "0px !important",
  },
  "& .MuiButton-endIcon": {
    marginLeft: "0px",
  },
  iconMenuMobile: {
    // height:"100%",
    padding: "15px 0px !important",
    "&:hover": {
      "& svg": {
        color: theme.palette.primary.main,
      },
    },
    "& svg": {
      color: "inherit",
    },
  },
  detailMenuMobile: {
    // overflow: "auto",
    "& a": {
      color: "inherit",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    "& .MuiTypography-root": {
      color: "inherit",
    },
  },
  iconArrowDown: {
    marginBottom: "4px",
  },
  coverSocial:{
    background:"#E3FBC2"
  },
  socialIcon: {
    width: 31,
    height: 28,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    color: "#FFFFFF",
    marginRight: 3,
    transition: "all 300ms ease-out",
    "&:focus": {
      color: theme.palette.primary.contrastText,
      backgroundColor: "transparent",
    },
    "&:hover": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.light,
    },
    "& > svg": {
      width: 20,
      height: 20,
    },
  },
  frameSocial:{
    padding:"4px 10px 2px 10px"
  },
  containerSocial:{
    padding:"13px 10px"
  }
});

export default headerLinksStyle;
