import weatherService from "app/services/weather";
import happyRainService from "app/services/happyrain";
import metplusService from "app/services/metplus";
export const HOME_SET_HISTORY = "HOME_SET_HISTORY";
export const HOME_SET_MAP = "HOME_SET_MAP";
export const HOME_SET_RECENT_NEWS = "HOME_SET_RECENT_NEWS";

export const HOME_STORM_INPUT_FORM = "HOME_STORM_INPUT_FORM";
export const HOME_USER_INPUT_FORM = "HOME_USER_INPUT_FORM";
export const HOME_SET_DIALOG_STATE = "HOME_SET_DIALOG_STATE";
export const HOME_RESET_USER_INPUT_FORM = "HOME_RESET_USER_INPUT_FORM";
export const HOME_SET_URL_PDF = "HOME_SET_URL_PDF"

export const setDialogState = (opened) => async (dispatch) => {
  dispatch({
    type: HOME_SET_DIALOG_STATE,
    payload: opened,
  });
};

export const fetchWeatherHistory = () => async (dispatch, getState) => {
  const searchHistory = getState().home.search.searchHistory;
  const locations = searchHistory.map((e) => ({
    lat: e?.location.lat,
    lng: e?.location.lng,
  }));

  if (locations.length > 0) {
    const weatherData = await weatherService.getWeatherHistory(locations);

    dispatch({
      type: HOME_SET_HISTORY,
      payload: searchHistory.map((e, index) => ({
        location: e?.location,
        data: weatherData[index].data,
      })),
    });
  }
};

export const addHistory = (data) => (dispatch, getState) => {
  const searchHistory = getState().home.search.searchHistory;
  // check if data is already added
  const found = searchHistory.find(
    (e) =>
      e?.location.lat === data.location.lat &&
      e?.location.lng === data.location.lng
  );
  if (found) return;

  // add data to the search history
  searchHistory.unshift(data);

  // remove older history
  if (searchHistory.length > 3) {
    searchHistory.pop();
    // searchHistory.splice(1, searchHistory.length - 1);
  }
  dispatch({
    type: HOME_SET_HISTORY,
    payload: [...searchHistory],
  });

  dispatch(fetchWeatherHistory());
};

export const setMap = (data) => (dispatch) => {
  dispatch({
    type: HOME_SET_MAP,
    payload: data,
  });
};

export const submitStormContactForm = (payload) => async (dispatch) => {
  const res = await happyRainService.callApiBackendNoAuth(
    "/guest/stormInform",
    payload
  );
  dispatch({
    type: HOME_STORM_INPUT_FORM,
    payload: res,
  });
};
export const submitUserContactForm = (payload) => async (dispatch) => {
  const res = await metplusService.callApiBackendNoAuth(
    "/guest/userInform",
    payload
  );
  // console.log("res slack : ",res)
  dispatch({
    type: HOME_USER_INPUT_FORM,
    payload: res,
  });
};

export const setUrlPdfClimate = (payload) => (dispatch) => {
  // console.log("data url :",payload)
  dispatch({
    type:HOME_SET_URL_PDF,
    payload:payload
  })
};

export const resetUserFormInput = () => (dispatch) => {
  dispatch({
    type: HOME_RESET_USER_INPUT_FORM,
  });
};
