const buttonStyle = (theme) => ({
  button: {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor:"#FF9900",
    color: theme.palette.primary.contrastText,
    border: "none",
    position: "relative",
    padding: "6px 8px",
    fontSize: 18,
    fontWeight: "400",
    textTransform: "capitalize",
    letterSpacing: "0",
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    // boxShadow: "10px 10px 20px 0 rgb(69 61 141 / 25%), -10px -10px 20px 0 #e9e8f58c",
    "&:hover,&:focus": {
      color: theme.palette.primary.contrastText,
      backgroundColor:"#FF9900",
      transform:"scale(1.09)"
    },
    "& svg": {
      position: "relative",
      display: "inline-block",
      top: "0",
      width: "24px",
      height: "24px",
      verticalAlign: "middle",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      padding: "8px 12px",
      textTransform: "unset",
    },
  },
  fullWidth: {
    width: "100%",
  },
  transparent: {
    "&,&:focus,&:hover": {
      color: "inherit",
      background: "transparent",
      boxShadow: "none",
    },
  },
  disabled: {
    opacity: "0.65",
    pointerEvents: "none",
  },
  lg: {
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
        marginTop: "-4px",
      },
    },
    padding: "1.125rem 2.25rem",
    fontSize: "0.875rem",
    lineHeight: "1.333333",
    borderRadius: "0.2rem",
  },
  sm: {
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
        marginTop: "1px",
      },
    },
    padding: "0.40625rem 1.25rem",
    fontSize: "0.6875rem",
    lineHeight: "1.5",
    borderRadius: "0.2rem",
  },
  round: {
    borderRadius: "10px",
  },
  block: {
    width: "100% !important",
  },
  link: {
    color: theme.palette.secondary.main,
    "&,&:hover,&:focus": {
      color: theme.palette.secondary.dark,
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  rightIcon: {
    paddingRight: "0px",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "0px",
    },
  },
  leftIcon: {
    paddingLeft: "10px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "8px",
    },
  },
});

export default buttonStyle;
