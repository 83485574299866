export const wpDomainApi =
  process.env.REACT_APP_ENV !== "prod"
    ? "http://portal.weatherplus.vn"
    : "https://weatherplus.vn";

// export const wpDomainApi = "http://localhost:3000";

export const wpDomainWeb =
  process.env.REACT_APP_ENV !== "prod"
    ? "http://portal.weatherplus.vn"
    : "https://weatherplus.vn";
export const wpDomainHappyRain =
  process.env.REACT_APP_ENV !== "prod"
    ? "https://api-gateway.weatherplus.vn/happyrain"
    : "https://api-gateway.weatherplus.vn/happyrain";
export const wpDomainMetplus =
  process.env.REACT_APP_ENV !== "prod"
    ? "https://api-gateway-dev.weatherplus.vn/metplus"
    : "https://api-gateway.weatherplus.vn/metplus";
export const showFormStormRegister = false;
