const locale = {
  // Navbar
  Weather: "Thời tiết & khí hậu",
  Products: "Sản phẩm",
  Solutions: "Giải pháp theo ngành",
  About: "Về chúng tôi",
  Contact: "Liên hệ",
  Home: "Trang chủ",
  "Weather forecasts": "Dự báo khí hậu",
  Weather4Business: "Business+",
  WeatherCareer: "Giải pháp theo ngành",
  "About us": "Giới thiệu",
  "Vision - Mission": "Tầm nhìn - Sứ mệnh",
  "Core value": "Giá trị cốt lõi",
  Milestones: "Lịch sử hình thành",
  Awards: "Giải thưởng",

  Projects: "Dự án",
  Agriculture: "Nông nghiệp",
  Hydroelectric: "Thuỷ điện",

  News: "Tin tức",
  NewsWeather:"Tin tức thời tiết",
  "Press releases": "Báo chí viết về chúng tôi",
  "Life at WeatherPlus": "Life@WeatherPlus",
  "Cooperation information": "Hợp tác phát triển",
  Technology: "Công nghệ và Giải pháp",

  Recruitment: "Tuyển dụng",
  "Job opportunities": "Tin tuyển dụng",
  "Employment policies": "Chính sách tuyển dụng",
  "Recruitment process": "Quy trình tuyển dụng",

  "Happy rain": "HAPPY RAIN",

  // Language
  English: "Tiếng Anh",
  Vietnamese: "Tiếng Việt",

  // Location dialog
  Agree: "Đồng ý",
  "Share your location?": "Chia sẻ địa điểm?",
  "Agree to see the weather in your location":
    "Chọn Đồng Ý để xem ngay thời tiết tại vị trí của bạn",

  // Footer
  Introduction:
    "WeatherPlus là doanh nghiệp tiên phong trong lĩnh vực dự báo thời tiết tại Việt Nam. Sứ mệnh của chúng tôi là giúp các cá nhân và doanh nghiệp quản lý được các thách thức liên quan đến thời tiết bằng các thông tin dự báo, dữ liệu thời tiết đủ tin cậy để có thể hành động.",
  Hotline: "Hotline",
  "Contact Information": "Thông tin liên hệ",
  "Contact us for consultations on weather solutions":
    "Liên hệ ngay với chúng tôi để được tư vấn về các giải pháp thời tiết.",
  Address: "Tầng 18, Tháp A, Tòa nhà Sông Đà, Phạm Hùng, Nam Từ Liêm, Hà Nội",
  "View map": "Xem bản đồ",
};

export default locale;
