import weatherService from "app/services/weather";

export const NEWS_SET_RECENT_NEWS = "NEWS_SET_RECENT_NEWS";
export const NEWS_SET_STORM_NEWS = "NEWS_SET_STORM_NEWS";
export const NEWS_SET_MOST_NEWS = "NEWS_SET_MOST_NEWS"

export const getRecentNews = (language) => async (dispatch) => {
  const recentNews = await weatherService.getRecentNews(language);

  dispatch({
    type: NEWS_SET_RECENT_NEWS,
    payload: recentNews,
  });
};
export const getStormNews = (language) => async (dispatch) => {
  const stormNews = await weatherService.getStormNews(language);

  dispatch({
    type: NEWS_SET_STORM_NEWS,
    payload: stormNews,
  })
}

export const getMostViewNews = (language,page) => async (dispatch) => {
  const mostViewNews = await weatherService.getMostViewNews(language,page);

  dispatch({
    type: NEWS_SET_MOST_NEWS,
    payload: mostViewNews.data.result.posts
  })
}

