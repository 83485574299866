import * as Actions from "../actions";

const initialState = {
  success: false,
  error: null,
  isLoading: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
  case Actions.AUTH_SUCCESSS:
    return {
      ...initialState,
      success: true,
      isLoading: false,
    };
  case Actions.AUTH_LOADING:
    return {
      ...state,
      isLoading: true,
    };
  case Actions.AUTH_ERROR:
    return {
      success: false,
      error: action.payload,
      isLoading: false,
    };
  default:
    return initialState;
  }
};

export default login;
